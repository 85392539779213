import styled from "@emotion/styled"
import {Card, CardActionArea, CardContent, CardMedia, Chip, Grid, Typography, TypographyProps} from "@material-ui/core"
import {StyledTextLink} from "../../../components/i18n/I18nLink"
import {BlogPost, isBlogPostWithParse} from "../../../api/ParseObjects"
import Img, {FluidObject} from "gatsby-image"
import {Post} from "../PostPage"
import defaultJumbo from "../../../data/images/index/background.jpg"
import {MdNoImages, MdWithParagraphs} from "../../../components/i18n/TransMdTypo"
import React from "react"
import {getFlag} from "./Flags"
import {PostEdit} from "../PostEdit"
import {Link, useTranslation} from "gatsby-plugin-react-i18next"
import {UserAvatar} from "../../../components/plus/UserAvatar"
import {ExternalLink, GrowGrid} from "../../../components/Shared"
import {GatsbyImage} from "gatsby-plugin-image";

const PostInsideCard = styled(Card)(({theme}) => ({
  position: "relative",
  backgroundColor: theme.palette.background.surface1,
  marginTop: "-50px"
}))
const PostCardMedia = styled(CardMedia)(({theme}) => ({
  height: "300px",
  width: "100%",
})) as typeof CardMedia
const PostDate = styled(Typography)(({theme}) => ({
  fontSize: "1em",
  color: theme.palette.text.secondary,
  fontWeight: "bold",
}))

const PostReadMore = (props: { className?: string, to: string, text?: string }) => {
  return (
    <StyledTextLink className={props.className} to={props.to}>
      {props.text || "Read more..."}
    </StyledTextLink>
  )
}

const OneLineTitle = (props: TypographyProps) => {
  return <Typography {...props} variant={"h4"} gutterBottom>{props.children}</Typography>
}
const MdOnLinesStyled = styled(MdNoImages)({
  overflow: "hidden",
  height: "10em",
  "*": {
    fontSize: "1em",
    fontWeight: 400
  }
})

const AlphaDiv = styled.div(({theme}) => ({
  position: "absolute",
  top: "0",
  height: "10em",
  background: `linear-gradient(0deg, ${theme.palette.background.surface1} 20%, ${theme.palette.background.surface1}00)`,
  width: "100%"
}))

const MdOnLines = (props: { children: string }) => {
  return <div css={{position: "relative"}}>
    <MdOnLinesStyled>{props.children}</MdOnLinesStyled>
    <AlphaDiv/>
  </div>
}

export const PostCard = (props: { post: BlogPost, full?: boolean, className?: string, plusInfo?: boolean }) => {
  const {post, full} = props
  const link = post.state === "published" ? Post.getUrl(post.i18nId) : Post.getUrl(post.i18nId, true)
  const {t} = useTranslation()
  return <Card className={props.className}>
    <CardActionArea>
      <Link to={link}>
        {isBlogPostWithParse(post) ?
          <PostCardMedia src={post.jumbo?._url ?? defaultJumbo} component={"img"}/>
          :
          <PostCardMedia>
            <GatsbyImage css={{height:"300px", width: "100%"}} image={post.localJumbo!.childImageSharp!.gatsbyImageData} alt={post.title} objectFit={"cover"}/>
          </PostCardMedia>
        }
      </Link>
    </CardActionArea>
    <CardContent>
      <PostInsideCard>
        <CardContent>
          <PostDate gutterBottom>{getFlag(props.post.language)} {post.publishDate.toDateString()}</PostDate>
          {full ?
            <React.Fragment>
              <Typography component={"h1"} variant={"h4"} gutterBottom>{post.title}</Typography>
              <MdWithParagraphs>{post.content}</MdWithParagraphs>
            </React.Fragment>
            :
            <React.Fragment>
              <OneLineTitle>{post.title}</OneLineTitle>
              <MdOnLines>{post.content}</MdOnLines>
              {props.plusInfo ?
                <PostReadMore to={PostEdit.getUrl(post.i18nId, post.language)} text={"EDIT..."}/>
                :
                <PostReadMore to={link}/>
              }
            </React.Fragment>
          }
        </CardContent>
      </PostInsideCard>
      <Grid container alignItems={"center"} alignContent={"center"} css={{marginTop: "1em"}}>
        {props.plusInfo &&
        <React.Fragment>
          <Grid item>
            <Chip css={{marginLeft: "1em"}} size={"small"} color={"secondary"} label={props.post.state}/>
          </Grid>
        </React.Fragment>
        }
        <Grid item>
          {post.tags.map(tag => <Chip css={{marginLeft: "0.5em"}} size={"small"} color={"primary"} label={tag}/>)}
        </Grid>
        <GrowGrid item/>
        <Grid item>
          <UserAvatar size={30}
                      url={isBlogPostWithParse(post) ? post.wroteBy?.attributes?.avatar?.url() : post.wroteBy.avatar.url}
                      online={false}/>
        </Grid>
        <Grid item>
          by <ExternalLink href={isBlogPostWithParse(post) ? post.wroteBy?.attributes?.twitter : post.wroteBy.twitter}>
          {isBlogPostWithParse(post) ? post.wroteBy?.getUsername() : post.wroteBy.username}</ExternalLink>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
}

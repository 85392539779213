import styled from "@emotion/styled"
import {
  AppBar,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Slide,
  StyledComponentProps,
  Tooltip,
  Typography
} from "@material-ui/core"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import {HiOutlineMenu} from "@react-icons/all-files/hi/HiOutlineMenu"
import {IoConstructOutline} from "@react-icons/all-files/io5/IoConstructOutline"
import {IoHomeOutline} from "@react-icons/all-files/io5/IoHomeOutline"
import {IoStorefrontOutline} from "@react-icons/all-files/io5/IoStorefrontOutline"
import {VscAccount} from "@react-icons/all-files/vsc/VscAccount"
import {Link} from "../../components/i18n/I18nLink"
import {GrowGrid} from "../../components/Shared"
import * as React from "react"
import {ReactNode, useEffect} from "react"
import {LanguageSelector} from "../../components/LanguageSelector"
import {RootState} from "../../store/store"
import {connect, ConnectedProps} from "react-redux"
import {NavList} from "./NavList"
import {IoGameControllerOutline} from "@react-icons/all-files/io5/IoGameControllerOutline"
import {IoLibraryOutline} from "@react-icons/all-files/io5/IoLibraryOutline"
import {IoCloudDownloadOutline} from "@react-icons/all-files/io5/IoCloudDownloadOutline"
import {IoNewspaperOutline} from "@react-icons/all-files/io5/IoNewspaperOutline"
import {IoChatbubbleEllipsesOutline} from "@react-icons/all-files/io5/IoChatbubbleEllipsesOutline"
import {IoHardwareChipOutline} from "@react-icons/all-files/io5/IoHardwareChipOutline"
import {IoChatbubblesOutline} from "@react-icons/all-files/io5/IoChatbubblesOutline"
import {graphql, useStaticQuery} from "gatsby"
import {Query} from "../../../graphql-types"
import {ImTv} from "@react-icons/all-files/im/ImTv"
import {useTranslation} from "gatsby-plugin-react-i18next"
import {FaPatreon} from "@react-icons/all-files/fa/FaPatreon"
import {usePatreon} from "../patreon/patron/usePatreon"
import {ProfilePage} from "../patreon/patron/ProfilePage";
import {GiFlame} from "@react-icons/all-files/gi/GiFlame";
export type NavigationBadge = { badge: React.ReactNode, color?: "default" | "primary" | "secondary" }
export type NavigationMenu = { id?: string, title: string, icon: JSX.Element, tooltip: string, to?: string, href?: string, logged?: boolean, badge?: NavigationBadge, activeWhen?: string, disabled?: boolean }

// Warning, setting Page.getUrl() in to elements here break the application
const menus = (lang: string): NavigationMenu[] => {
  return [
    {
      title: "navigation^Home",
      icon: <IoHomeOutline/>,
      tooltip: "navigation^Home",
      to: "/",
    },
    {
      title: "Recalbox x Patreon",
      icon: <FaPatreon/>,
      tooltip: "Recalbox x Patreon",
      to: "/patreon/",
      disabled: process.env.GATSBY_PATREON_ENABLED !== "true",
    },
    {
      title: "Recalbox RGB Dual",
      icon: <ImTv/>,
      tooltip: "Recalbox RGB Dual",
      to: "/recalbox-rgb-dual/",
    },
    {
      title: "Recalbox RGB JAMMA",
      icon: <GiFlame/>,
      tooltip: "Recalbox RGB JAMMA",
      to: "/recalbox-rgb-jamma/",
    },
    {
      title: "navigation^Create your Recalbox",
      icon: <IoGameControllerOutline/>,
      tooltip: "navigation^Create your Recalbox",
      to: "/diy/1-discover/",
    },
    {
      title: "navigation^Download",
      icon: <IoCloudDownloadOutline/>,
      tooltip: "navigation^Download",
      to: "/download/stable/",
    },
    {
      title: "navigation^Shop",
      icon: <IoStorefrontOutline/>,
      tooltip: "navigation^Recalbox Shop",
      href: "https://shop.recalbox.com",
    },
    {
      title: "navigation^Blog",
      icon: <IoNewspaperOutline/>,
      tooltip: "navigation^Blog",
      to: "/blog/",
      id: "blog",
    },
    {
      title: "navigation^Tutorials",
      icon: <IoConstructOutline/>,
      tooltip: "navigation^Tutorials",
      to: "/tutorials/",
    },
    {
      title: "navigation^Forum",
      icon: <IoChatbubblesOutline/>,
      tooltip: "navigation^Forum",
      href: "https://forum.recalbox.com/",
    },
    {
      title: "navigation^Documentation",
      icon: <IoLibraryOutline/>,
      tooltip: "navigation^Wiki",
      href: `https://wiki.recalbox.com/${lang === "fr" ? "fr/home" : ""}`,
    },
    {
      title: "navigation^F.A.Q",
      icon: <IoChatbubbleEllipsesOutline/>,
      tooltip: "navigation^Frequently Asked Questions",
      to: "/faq/",
    },
    {
      title: "navigation^Supported Systems",
      icon: <IoHardwareChipOutline/>,
      tooltip: "navigation^Compatible systems and games",
      to: "/database/",
    },
  ]
}

const BottomChildren = styled.div`
  ${({theme}: any) => `
    text-align: center;
    position: fixed;
    margin-bottom: ${theme.spacings.md};
    bottom:0;
`};
`

const Header = styled.div`
  ${({theme}: any) => `
    min-height: ${theme.toolbar.height}px;
`};
`

const SToolbar = styled.div`
  ${({theme}: any) => `
    background-color: ${theme.palette.primary.main};
    @media (min-width: ${theme.breakpoints.values.sm}px) {
      padding-left: ${theme.nav.width.xs}px;
    }
    @media (min-width: ${theme.breakpoints.values.md}px) {
      padding-left: ${theme.nav.width.sm}px;
    }
    height: ${theme.toolbar.height}px;
`};
`


const TitleTypography = styled(Typography)<StyledComponentProps & { component: string }>`
  ${({theme}) => `
    padding-left: ${theme.spacings.md};
    margin-top: auto;
    margin-bottom: auto;
    height: ${theme.toolbar.height}px;
    line-height: ${theme.toolbar.height}px;
    font-weight: 500;
    font-size: 2rem;
    @media (min-width: ${theme.breakpoints.values.xs}px) {
        font-size: 1rem;
    }
    @media (min-width: ${theme.breakpoints.values.sm}px) {
        display: inline;
        font-size: 1.5rem;
    }
    display: inline;
    white-space: nowrap;
`}
`

const OpenMenuButton = styled(IconButton)`
  ${({theme}: any) => `
@media (min-width: ${theme.breakpoints.values.sm}px) {
        display: none;
    }
`}
`
const GridOverflowHidden = styled(Grid)({
  overflow: "hidden"
})


const RecalboxAppBar = (props: { onClick: () => void, title: string, children?: React.ReactNode, patron: boolean }) => {
  const trigger = useScrollTrigger({})
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <AppBar position="fixed">
        <SToolbar>
          <Grid container wrap="nowrap" alignItems="center">
            <Grid item>
              <OpenMenuButton color="inherit" aria-label="open drawer" onClick={props.onClick}>
                <HiOutlineMenu/>
              </OpenMenuButton>
            </Grid>

            <GridOverflowHidden item>
              <TitleTypography component="h1" variant="h5">
                {props.title}
              </TitleTypography>
            </GridOverflowHidden>
            <GrowGrid item/>
            <Grid item>
              {props.children}
              <LanguageSelector/>
            </Grid>
            {
              props.patron && (
                <React.Fragment>
                  <Grid item>
                    <Tooltip title={"Patreon Area"} placement={"left"}>
                    <IconButton
                      component={Link}
                      to={ProfilePage.getUrl()}
                      aria-label="account of current user"
                      aria-haspopup="true"
                      color="inherit"

                    >
                      <VscAccount/>
                    </IconButton>
                    </Tooltip>
                  </Grid>
                </React.Fragment>
              )}

          </Grid>
        </SToolbar>
      </AppBar>
    </Slide>
  )
}

function WebNavigation(props: { open: boolean, onClose: () => void, blogToRead: number }) {
  const {i18n} = useTranslation()
  const navListProps = {logged: false, menus: menus(i18n.language), blogToRead: props.blogToRead}
  return <React.Fragment>
    <Hidden xsUp implementation="css">
      <Drawer
        variant="temporary"
        open={props.open}
        onClose={props.onClose}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
      >
        {/*<LoggedHeader logged={props.logged} user={props.user}/>*/}
        <NavList {...navListProps} />
      </Drawer>
    </Hidden>
    <Hidden xsDown mdUp implementation="css">
      <Drawer variant="permanent">
        {/*<LoggedHeader logged={props.logged} user={props.user}/>*/}
        <NavList {...navListProps} variant={"small"} switchMenuOpen={props.onClose}/>
      </Drawer>
    </Hidden>
    <Hidden smDown implementation="css">
      <Drawer variant="permanent">
        {/*<LoggedHeader logged={props.logged} user={props.user}/>*/}
        <NavList {...navListProps} />
      </Drawer>
    </Hidden>
  </React.Fragment>
}

const Navigation = (props: NavigationProps) => {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [patron, setPatron] = React.useState(false)
  const patreon = usePatreon()
  useEffect(() => {
    patreon.isPatron().then(patron => setPatron(patron))
  }, [])

  const query = useStaticQuery<Query>(graphql`
    query MyQuery {
      allBlogPost(sort: {fields: publishDate___iso}, limit: 1) {
        edges {
          node {
            i18nId
            publishDate {
              iso
            }
          }
        }
      }
    }
  `)
  let blogToRead = 0
  const lastBlogPost = query.allBlogPost.edges[0]

  if (props.blogInfo?.lastVisit === 0) {
    blogToRead = 1
  } else if (lastBlogPost.node.publishDate?.iso > new Date(props.blogInfo!.lastVisit).toISOString()) {
    blogToRead = 1
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <React.Fragment>
      <React.Fragment>
        {!props.hideAppBar &&
        <RecalboxAppBar onClick={handleDrawerToggle} title={props.title} children={props.children}
                        patron={patron}/>
        }
        <WebNavigation open={mobileOpen} onClose={handleDrawerToggle} blogToRead={blogToRead}/>
      </React.Fragment>
    </React.Fragment>
  )
}

const connector = connect((state: RootState) => {
  return {
    blogInfo: state.profile.blogInfo
  }
})

type NavigationProps = ConnectedProps<typeof connector> & { children?: ReactNode, title: string, hideAppBar?: boolean }
export const Nav = connector(Navigation)

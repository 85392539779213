import {Page} from "../../../components/PrivateRoutePage"
import {graphql, useStaticQuery} from "gatsby"
import {Query} from "../../../../graphql-types"
import {AllImagesComponent} from "../../download/components/AllImagesComponent"
import {Layout} from "../../../components/Layout"
import {ResponsiveContainer, SectionContainer, StarsImageContainer} from "../../../components/Shared"
import React from "react"
import {useBreadcrumb} from "../../../hooks/useBreadcrumb"
import {ProfilePage} from "./ProfilePage"
import {useTranslation} from "gatsby-plugin-react-i18next"
import {TransMdTypo} from "../../../components/i18n/TransMdTypo";
import {PatronNav} from "./PatronNav";
import {Box, Card, CardContent} from "@material-ui/core";

const DownloadPatronVersion = () => {
  const {t} = useTranslation()
  const breadCrumb = useBreadcrumb(DownloadPatronVersionPage)
  const query = useStaticQuery<Query>(
    graphql`
        query {
          allDownloadBoardsYaml(filter: { legacy: {ne: true}}) {
            edges {
              node {
                id
                name
                imagename
                defaultInstall
                rpiImagerImageName
                tags
                legacy
                recalboxVersion
                picture {
                  childImageSharp {
                    gatsbyImageData(width: 60, placeholder: BLURRED)
                  }
                }
              }
            }
          }
          allRecalboxChannelsYaml(filter: { name: {eq: "patron"}}) {
            edges {
              node {
                version
                name
                domain
                releasenotes
                description
              }
            }
          }
        }
    `
  )

  const boards = query.allDownloadBoardsYaml.edges.map(_ => _.node)
  const channels = query.allRecalboxChannelsYaml.edges.map(_ => _.node)
  const channel = channels.length === 1 && channels[0]
  if (!channel) return <div/>

  return <Layout title={t("patreon^Download Recalbox Patron Edition")}>
    <StarsImageContainer>
      <SectionContainer>
        {breadCrumb}
      </SectionContainer>
      <ResponsiveContainer>
        <PatronNav tab={"download"}/>

        <Box marginTop={3}></Box>
        <AllImagesComponent
          channel={channel}
          boards={boards}
          ignoreBoardVersion
          showReleaseNote
          extraMessage={<Card>
            <CardContent>
            <TransMdTypo gutterBottom>patreon^Download Patron Edition for features</TransMdTypo>
            <TransMdTypo gutterBottom>patreon^Or update your current version!</TransMdTypo>
          </CardContent>
          </Card>}
        />
      </ResponsiveContainer>
    </StarsImageContainer>

  </Layout>
}

const getUrl = () => "/patreon/patron/download/"

export const DownloadPatronVersionPage: Page = {
  Page: DownloadPatronVersion,
  getUrl,
  parentPage: () => ProfilePage,
  breadCrumb: {link: getUrl(), name: "Download Recalbox"}
}

import styled from "@emotion/styled";
import React from "react";
import {Card, CardContent, CardMedia, Chip, Grid, Tooltip, Typography} from "@material-ui/core";
import interogationImage from "../../../../data/images/patreon/goodies/defaultwallpaper.png";
import {FaDownload} from "@react-icons/all-files/fa/FaDownload";
import {GrowGrid} from "../../../../components/Shared";
import {Link} from "gatsby-plugin-react-i18next";

const CardImage = styled.img(() => {
  return {
    height:"300px",
    width: "100%",
    objectFit: "cover"
  }
})

export const GoodieCard = (props: { image: string, link?: string, children: React.ReactNode, loading?: boolean, imgContain?:boolean }) => {
  return <Grid item xs={12} md={6} lg={4} css={{position: "relative"}}>
    <Card css={(theme) => ({":hover": {backgroundColor: theme.palette.background.surface1}})}>
      <CardMedia>
        {props.loading ?
            <CardImage alt={""} src={interogationImage}/>
          :
          props.link ?
            <Link to={props.link}>
              <CardImage alt={""} src={props.image} css={{objectFit: props.imgContain ? "contain" : "cover"}}/>
            </Link>
            :
            <CardImage alt={""} src={props.image} css={{objectFit: props.imgContain ? "contain" : "cover"}}/>
        }
      </CardMedia>
      <CardContent>
        {props.children}
      </CardContent>
    </Card>
  </Grid>
}

export const IncomingCard = () => {
  return <GoodieCard image={interogationImage} link={""}>
    <Typography>Soon</Typography>
    <Grid container alignContent={"center"} alignItems={"center"}>
      <GrowGrid item/>
      <Grid item>
        <Chip size={"small"} label={"Coming soon"} css={{marginRight: "0.5em"}}/>
      </Grid>
    </Grid>
  </GoodieCard>
}

export const LoadingCard = () => {
  return <GoodieCard image={interogationImage} loading>
    <Typography>Loading</Typography>
    <Grid container alignContent={"center"} alignItems={"center"}>
      <Grid item>
        <Tooltip title={"Download"} placement={"right"}><FaDownload/></Tooltip>
      </Grid>
      <GrowGrid item/>
      <Grid item>
        <Chip size={"small"} label={"Coming soon"} css={{marginRight: "0.5em"}}/>
      </Grid>
    </Grid>
  </GoodieCard>
}

import {Dialog as MuiDialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core"
import React from "react"


export const Dialog = (props: { display: boolean, title: string, text: string, handleClose: () => void, actions: { button: React.ReactElement, callback: () => void }[] }) => {
  const handleClose = () => {
    props.handleClose()
  }
  return (
    <MuiDialog
      open={props.display}
      onClose={handleClose}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {props.actions.map(a => (
          <a onClick={() => {
            a.callback()
            handleClose()
          }}>
            {a.button}
          </a>
        ))}
      </DialogActions>
    </MuiDialog>
  )
}

import {Card, Grid} from "@material-ui/core"
import {
  ResponsiveContainer,
  SectionContainer,
  StarsImageContainer,
  UnstyledExternalLink
} from "../../../../components/Shared"
import {usePatreon} from "../usePatreon"
import React, {useEffect, useState} from "react"
import {useTranslation} from "gatsby-plugin-react-i18next"
import {useLocation} from "@reach/router"
import {WallPaper} from "../../../../../../patreon-api/src/badges/Types"
import styled from "@emotion/styled";
import {PatronNav} from "../PatronNav";
import {Layout} from "../../../../components/Layout";
import {useBreadcrumb} from "../../../../hooks/useBreadcrumb";
import loadingImage from "../../../../data/images/patreon/goodies/soon.png";
import {GoodiesPage} from "../GoodiesPage";
import {Page} from "../../../../components/PrivateRoutePage";

const FullwidthImage = styled.img({
  maxWidth: "100%",
  maxHeight: "70vh"
})

const getFromUrl = (path: string) => {
  const wp = new RegExp("/wp/([0-9]+)$")
  const match = path.match(wp)
  if (match && match[1]) {
    return parseInt(match[1])
  }
  return null
}

export const Wallpaper = () => {
  const patreon = usePatreon()
  const loc = useLocation()
  const {t} = useTranslation()
  const breadCrumb = useBreadcrumb(GoodiesPage)
  const [state, setState] = useState({
    currentWp: undefined as WallPaper | undefined,
  })

  useEffect(() => {
    const currentGoodie = getFromUrl(loc.href)
    if (currentGoodie != null) {
      patreon.getBadges().then((badges) => {
        const wp = badges.filter(b => b.unlocked)
          .map(badge => badge.wallpaperUnlock)
          .reduce((acc, cur) => acc.concat(cur), [])
          .find(wp => wp.uid === currentGoodie)
        setState({
          ...state,
          currentWp: wp,
        })
      })
    }
  }, [])

  return <Layout title={t("patreon^Wallpaper")}>
    <StarsImageContainer>
      <SectionContainer>
        {breadCrumb}
      </SectionContainer>
      <ResponsiveContainer>
        <PatronNav tab={"goodies"}/>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <UnstyledExternalLink href={state.currentWp?.url} target={"_blank"}>
                <FullwidthImage src={state.currentWp?.url ?? loadingImage}/>
              </UnstyledExternalLink>
            </Card>
          </Grid>
        </Grid>
      </ResponsiveContainer>
    </StarsImageContainer>
  </Layout>
}

const getUrl = (uid: string) => `/patreon/patron/goodies/wp/${uid}`
export const WallpaperPage: Page =
  {
    Page: Wallpaper,
    getUrl,
    parentPage: () => GoodiesPage,
  }

import {Hidden, MenuItem} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"
import {MdExpandMore} from "@react-icons/all-files/md/MdExpandMore"
import {MdTranslate} from "@react-icons/all-files/md/MdTranslate"
import {Link, useI18next, useTranslation} from "gatsby-plugin-react-i18next"
import React, {useState} from "react"

export function LanguageSelector() {
  const {language, languages, originalPath} = useI18next()
  const {t} = useTranslation()
  const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLButtonElement | null>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <React.Fragment>
      <Button color={"inherit"} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <MdTranslate/>
        <Hidden smDown>
          {t(`languages^${language}`)}
        </Hidden>
        <MdExpandMore/>

      </Button>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {languages.map((lng) => (
          <MenuItem key={lng}>
            <Link css={{color: "inherit"}} to={originalPath} language={lng}>
              {t(`languages^${lng}`)}
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  )
}

import styled from "@emotion/styled"
import {Link as I18nLink, useI18next} from "gatsby-plugin-react-i18next"

export const Link = styled(I18nLink)({
  color: "inherit"
})

export const StyledTextLink = styled(I18nLink)(({theme}) => ({
  color: "inherit",
  textTransform: "uppercase",
  ":hover": {
    color: theme.palette.primary.light
  }
}))

export const UnstyledLink = styled(I18nLink)(({theme}) => ({
  color: "inherit",
  fontWeight: "inherit",
  textTransform: "none",
  ":hover": {
    color: "inherit",
  }
}))

export const useI18nLink = () => {
  const {language, defaultLanguage} = useI18next()
  return {
    getLink: (link: string) => {
      if (language === defaultLanguage) return link
      if (link.startsWith("/"))
        link = link.substring(1)
      return `/${language}/${link}`
    }
  }
}

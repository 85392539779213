import {Layout} from "../../components/Layout"
import {Page} from "../../components/PrivateRoutePage"
import {useTranslation} from "gatsby-plugin-react-i18next"
import {ExternalLink, SectionContainer, SectionTitle} from "../../components/Shared"
import {TransMdTypo} from "../../components/i18n/TransMdTypo"
import {StaticImage} from "gatsby-plugin-image"
import styled from "@emotion/styled"
import {FaqEntry, FaqResposiveContainer, FaqSummary} from "../faq/FaqPage"
import * as React from "react"
import {AppTableOfContents} from "../faq/components/AppTableOfContent"
import {Grid, Hidden} from "@material-ui/core"
import {TextCenter} from "../diy/components/Shared"
import {RGBDualPage} from "./RGBDualPageNext"
import {useBreadcrumb} from "../../hooks/useBreadcrumb"

const StaticImageContainer = styled.div((props: { className?: string, centered?: boolean, maxHeight?: string, maxWidth?: string }) => ({
  textAlign: props.centered ? "center" : "inherit",
  margin: "auto",
  maxWidth: props.maxWidth ?? "100%",
  img: {
    maxHeight: props.maxHeight ?? "20em",
    margin: "auto",
    padding: "1em",
  },
}))


const RecalboxRGBDualManual = () => {
  const {t} = useTranslation()
  const chapters = [
    {
      id: "compatibility",
      title: t("rrgbdmanual^Compatibility"),
      content: <div><TransMdTypo>rrgbdmanual^Recalbox RGB Dual is plug and play with Recalbox since the 8.1-Electron version.</TransMdTypo></div>
    },
    {
      id: "prerequites",
      title: t("rrgbdmanual^Prerequisites"),
      content: <div><TransMdTypo>rrgbdmanual^To use Recalbox RGB Dual you will need</TransMdTypo>
        <TransMdTypo>rrgbdmanual^List of prerequisites</TransMdTypo>
      </div>
    },
    {
      id: "connexion",
      title: t("rrgbdmanual^Connection"),
      content: <div>
        <TransMdTypo>rrgbdmanual^Simple to connect</TransMdTypo>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <StaticImageContainer>
              <StaticImage src={"../../data/images/rgbdual-manual/connect-not-good.png"}
                           alt={"Connect Not Good"}/>
            </StaticImageContainer>
          </Grid>
          <Grid item xs={12} lg={6}>
            <StaticImageContainer>
              <StaticImage src={"../../data/images/rgbdual-manual/connect-good.png"}
                           alt={"Connect Good"}/>
            </StaticImageContainer>
          </Grid>
        </Grid>

      </div>
    },
    {
      id: "rpi3-4",
      title: t("rrgbdmanual^RPi 3 and 4"),
      content: <div>
        <TransMdTypo>rrgbdmanual^Easy on rpi3 and 4</TransMdTypo>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <StaticImageContainer>
              <StaticImage src={"../../data/images/rgbdual-manual/pi4-step1.png"}
                           alt={"Connect on Rpi 3 or 4 step 1"}/>
            </StaticImageContainer>
          </Grid>
          <Grid item xs={12} lg={6}>
            <StaticImageContainer>
              <StaticImage src={"../../data/images/rgbdual-manual/pi4-step2.png"}
                           alt={"Connect on Rpi 3 or 4 step 2"}/>
            </StaticImageContainer>
          </Grid>

        </Grid>
        <TransMdTypo>rrgbdmanual^And plug the scart cable</TransMdTypo>

        <StaticImageContainer centered>
          <StaticImage src={"../../data/images/rgbdual-manual/rgbdual_and_scart.png"}
                       alt={"Connect Scart cable on Recalbox RGB Dual"}/>
        </StaticImageContainer>
      </div>
    },
    {
      id: "extender",
      title: t("rrgbdmanual^GPIO Extender"),
      content: <div>
        <TransMdTypo>rrgbdmanual^GPIO Extender - Description</TransMdTypo>
        <StaticImageContainer centered maxHeight={"40em"}>
          <StaticImage src={"../../data/images/rgbdual-manual/extender-rpi4.png"}
                       alt={"Connect with gpio extender on rpi 4"}/>
        </StaticImageContainer>
      </div>
    },
    {
      id: "pi400",
      title: t("rrgbdmanual^Raspberry Pi 400"),
      content: <div>
        <TransMdTypo>rrgbdmanual^Connect a Raspberry Pi 400</TransMdTypo>
        <StaticImageContainer centered maxHeight={"40em"}>
          <StaticImage src={"../../data/images/rgbdual-manual/rpi400.png"}
                       alt={"Connect with gpio extender on rpi 400"}/>
        </StaticImageContainer>
      </div>
    },
    {
      id: "inacase",
      title: t("rrgbdmanual^Usage with a case"),
      content: <div>
        <TransMdTypo>rrgbdmanual^Usage with a case details</TransMdTypo>
      </div>
    },
    {
      id: "argonone",
      title: t("rrgbdmanual^Argon One"),
      content: <div>
        <TransMdTypo>rrgbdmanual^Connect on an Argon One</TransMdTypo>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <StaticImageContainer centered maxHeight={"40em"}>
              <StaticImage src={"../../data/images/rgbdual-manual/argon-one-extender.png"}
                           alt={"Connect with gpio extender on an argon one with extender"}/>
            </StaticImageContainer>
          </Grid>
          <Grid item xs={12} lg={6}>
            <StaticImageContainer centered maxHeight={"40em"}>
              <StaticImage src={"../../data/images/rgbdual-manual/argon-one.png"}
                           alt={"Connect with gpio extender on an argon one"}/>
            </StaticImageContainer>
          </Grid>
        </Grid>
      </div>
    },
    {
      id: "50hz",
      title: t("rrgbdmanual^50Hz mode"),
      content: <div>
        <TransMdTypo>rrgbdmanual^When you have a 50 hz TV</TransMdTypo>
        <StaticImageContainer centered maxWidth={"400px"}>
          <StaticImage src={"../../data/images/rgbdual-manual/switch-50hz.png"}
                       alt={"50Hz switch"}/>
        </StaticImageContainer>
      </div>
    },
    {
      id: "31khz",
      title: t("rrgbdmanual^31kHz monitors"),
      content: <div>
        <TransMdTypo>rrgbdmanual^When you have a 31kHz monitor</TransMdTypo>
        <StaticImageContainer centered maxWidth={"400px"}>
          <StaticImage src={"../../data/images/rgbdual-manual/switch-31khz.png"}
                       alt={"31kHz switch"}/>
        </StaticImageContainer>
      </div>
    },
    {
      id: "configuration",
      title: t("rrgbdmanual^Configuration"),
      content: <div>
        <TransMdTypo>rrgbdmanual^Configuration of recalbox</TransMdTypo>
        <StaticImageContainer centered>
          <StaticImage src={"../../data/images/rgbdual-manual/options-screen.png"}
                       alt={"Options screen"}/>
        </StaticImageContainer>
        <TransMdTypo>rrgbdmanual^All options</TransMdTypo>

      </div>
    },
    {
      id: "rpizero2",
      title: "Rpi Zero 2",
      content: <div>
        <TransMdTypo>rrgbdmanual^Connect on RPi Zero 2</TransMdTypo>
      </div>
    },
    {
      id: "Kodi",
      title: "Kodi",
      content: <div>
        <TransMdTypo>rrgbdmanual^Kodi details</TransMdTypo>
        <StaticImageContainer centered maxHeight={"30em"}>
          <StaticImage src={"../../data/images/rgbdual-manual/kodi-options.png"}
                       alt={"Kodi options screen"}/>
        </StaticImageContainer>
      </div>
    },
    {
      id: "themes",
      title: "Theme",
      content: <div>
        <TransMdTypo>rrgbdmanual^Themes details</TransMdTypo>
      </div>
    },
    {
      id: "F.A.Q",
      title: "F.A.Q",
      content: <div>
        <TransMdTypo>rrgbdmanual^F.A.Q</TransMdTypo>
      </div>
    },
  ]


  const breadCrumb = useBreadcrumb(RecalboxRGBDualManualPage)
  return <Layout title={t("Recalbox RGB Dual Manual")}>
    <FaqResposiveContainer>
      <SectionContainer>
        {breadCrumb}
      </SectionContainer>
      <TextCenter>
        <StaticImage css={{maxWidth: "300px", objectFit: "cover"}} src={"../../data/images/rgb-dual-timeline/logo.png"}
                     alt={"Logo"}/>
        <TransMdTypo variant={"h1"} css={{margin: "1em"}}>
          Recalbox RGB Dual Manual
        </TransMdTypo>
      </TextCenter>
      <SectionContainer>
        <TransMdTypo css={{marginBottom:"2em"}}>rrgbdmanual^Thank you for your support</TransMdTypo>
        {chapters.map((chapter, index) => {
          return <div id={chapter.id} key={chapter.id}>
            <SectionTitle>
              {`${index + 1} - ${chapter.title}`}
            </SectionTitle>
            <FaqEntry>
              {chapter.content}
            </FaqEntry>
          </div>
        })}
      </SectionContainer>
    </FaqResposiveContainer>
    <Hidden smDown>
      <FaqSummary>
        <AppTableOfContents items={chapters.map((chapter, index) => ({
          text: `${index + 1} - ${chapter.title}`,
          children: [],
          hash: chapter.id
        }))}/>
      </FaqSummary>
    </Hidden>
  </Layout>
}

const getUrl = () => "/recalbox-rgb-dual/manual"
export const RecalboxRGBDualManualPage: Page = {
  Page: RecalboxRGBDualManual,
  getUrl,
  parentPage: () => RGBDualPage,
  breadCrumb: {name: "Manual", link: getUrl()}
}

import {StepLabel} from "@material-ui/core"
import styled from "@emotion/styled"
import downloadImage from "../../../data/images/download/download.png"
import playImage from "../../../data/images/download/play.png"
import {GatsbyImage, getImage, StaticImage} from "gatsby-plugin-image"
import {UnstyledLink} from "../../../components/i18n/I18nLink"
import {ChannelPage} from "../ChannelPage"
import {CategoryPage} from "../CategoryPage"
import {ReactElement} from "react"
import {DownloadBoardsYaml, DownloadCategoriesYaml, File, Maybe, RecalboxChannelsYaml} from "../../../../graphql-types"
import {FileNode} from "gatsby-plugin-image/dist/src/components/hooks"
import {useTranslation} from "gatsby-plugin-react-i18next"
import {ResponsiveStep, ResponsiveStepper} from "../../../components/ResponsiveStep"
import whatImage from "../../../data/images/download/what.png"
import {BoardPage} from "../BoardPage";

const StepImageIcon = styled.div(({theme}) => ({
  width: "4em",
  height: "4em",
  position: "relative",
  img: {
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    maxWidth: "80%",
    maxHeight: "80%",
    margin: "auto",
  }
}))

const DownloadImage = () => <StepImageIcon>
  <img src={downloadImage} alt={""}/>
</StepImageIcon>
const PlayImage = () => <StepImageIcon>
  <img src={playImage} alt={""}/>
</StepImageIcon>

const StepLabelBlock = styled(StepLabel)({
  display: "block",
  textAlign: "center",
  ".MuiStepLabel-iconContainer": {
    paddingRight: "0",
    borderRadius: "1em",
  }
})

export enum Steps {
  //Channel,
  Category,
  Board,
  Install,
  Play
}


const DownloadStep = styled(ResponsiveStep)(({theme}) => ({
  textAlign: "center",
  margin: "auto",
  ".MuiStepLabel-iconContainer": {
    margin: "auto",
    width: "4em",
    height: "4em",
    backgroundColor: theme.palette.background.surface1,
  },
  "&.active": {
    ".MuiStepLabel-iconContainer": {
      backgroundColor: theme.palette.secondary.main,
    }
  },
  "&.completed": {
    ".MuiStepLabel-iconContainer": {
      backgroundColor: theme.palette.secondary.dark,
    }
  }
}))

const MaybeLinked = (props: { children: ReactElement, to?: string | null }) => {
  if (props.to) {
    return <UnstyledLink to={props.to}>
      {props.children}
    </UnstyledLink>
  } else {
    return props.children
  }

}
const ImageComp = (alt: string, picturable?: { picture?: Maybe<File> }) => {
  if (picturable && picturable.picture) {
    return () => <StepImageIcon><GatsbyImage image={getImage((picturable.picture as any) as FileNode)!}
                                             alt={alt}/></StepImageIcon>
  }
  return () => <StepImageIcon><StaticImage src={"../../../data/images/download/what.png"}
                                           alt={alt}/></StepImageIcon>
}
const channelImage = (channel?: RecalboxChannelsYaml) => () => <StepImageIcon><img src={channel?.logo?.publicURL ?? whatImage}
                                                                                          alt={`${channel?.name ?? "No"} channel`}/></StepImageIcon>


export const DownloadStepper = (props: { step: Steps, channel?: RecalboxChannelsYaml, category?: DownloadCategoriesYaml, board?: DownloadBoardsYaml }) => {
  const {step, channel, category, board} = props
  const {t} = useTranslation()
  const getClass = (step: Steps, currentStep: Steps) => {
    return step === currentStep ? "active" : step < currentStep ? "completed" : ""
  }
  return <ResponsiveStepper activeStep={props.step.valueOf()}>
   {/* <DownloadStep key={Steps.Channel} className={getClass(Steps.Channel, step)}>
      <MaybeLinked to={ChannelPage.getUrl()}>
        <StepLabelBlock StepIconComponent={channelImage(channel)}>{t("download^Channel")}</StepLabelBlock>
      </MaybeLinked>
    </DownloadStep>*/}
    <DownloadStep key={Steps.Category} className={getClass(Steps.Category, step)}>
      <MaybeLinked to={channel?.name && CategoryPage.getUrl(channel.name)}>
        <StepLabelBlock
          StepIconComponent={ImageComp(t("download^Category"), category)}>{t("download^Category")}</StepLabelBlock>
      </MaybeLinked>
    </DownloadStep>
    <DownloadStep key={Steps.Board} className={getClass(Steps.Board, step)}>
      <MaybeLinked to={board?.name && channel?.name && BoardPage.getUrl(channel.name, board.id)}>
        <StepLabelBlock StepIconComponent={ImageComp(t("download^Board"), board)}>{t("download^Board")}</StepLabelBlock>
      </MaybeLinked>
    </DownloadStep>
    <DownloadStep key={Steps.Install} className={getClass(Steps.Install, step)}>
      <StepLabelBlock StepIconComponent={DownloadImage}>{t("download^Install")}</StepLabelBlock>
    </DownloadStep>
    <DownloadStep key={Steps.Play} className={getClass(Steps.Play, step)}>
      <StepLabelBlock StepIconComponent={PlayImage}>{t("download^Play!")}</StepLabelBlock>
    </DownloadStep>
  </ResponsiveStepper>
}

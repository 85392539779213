import {Grid} from "@material-ui/core"
import {Link, useTranslation} from "gatsby-plugin-react-i18next"
import * as React from "react"
import {Layout} from "../../components/Layout"
import {DiySteps} from "./components/DiySteps"
import {CTAButton, PaddedGrid, PreTitle, Title} from "./components/Shared"

import {Page} from "../../components/PrivateRoutePage"
import {ResponsiveContainer} from "../../components/Shared"
import {CgArrowRight} from "@react-icons/all-files/cg/CgArrowRight"
import {Diy4Install} from "./Diy4Installation"
import {DiyHardwareCard} from "./components/DiyHardwareCard"
import {graphql, useStaticQuery} from "gatsby"
import {Query} from "../../../graphql-types"
import {KitCard} from "./components/KitCard"
import {useI18nLink} from "../../components/i18n/I18nLink"

const Diy3HandheldPage = () => {
  const {t} = useTranslation()
  const i18link = useI18nLink()
  const kits = useStaticQuery<Query>(graphql`query KubiiKitHandeldQuery {
    allKubiiKitsYaml(filter: {type: {eq: "handheld"}}) {
      edges {
        node {
          description
          id
          name
          url
          type
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
  `)
  return (

    <Layout title={t("diy^DIY Step 3 Handheld")}>
      <DiySteps active={2}/>
      <ResponsiveContainer>
        <PaddedGrid container spacing={3}>
          <Grid item xs={12} md={9} lg={9}>
            <PreTitle>
              {t("diy^Get your recalbox everywhere")}
            </PreTitle>
            <Title>{t("diy^Step 3 : Compatible Handhelds")}</Title>
          </Grid>
          <Grid xs={12} item>
            <DiyHardwareCard title={t("diy^Choose your handheld!")}
                             subtitle={t("diy^Get handhelds at kubii", {link: i18link.getLink(Diy4Install.getUrl(Diy3Handheld.getUrl()))})}>
              <Grid container spacing={2}>
                {kits.allKubiiKitsYaml.edges.map(edge => edge.node).map(kit =>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <KitCard kit={kit}/>
                  </Grid>
                )}
              </Grid>
            </DiyHardwareCard>
          </Grid>
        </PaddedGrid>
        <PaddedGrid container>
          <CTAButton css={{marginTop: "1em"}} endIcon={<CgArrowRight/>} component={Link}
                     to={Diy4Install.getUrl(Diy3Handheld.getUrl())} variant="outlined"
                     color="secondary">
            {t("diy^Step 4: Installation")}
          </CTAButton>
        </PaddedGrid>
      </ResponsiveContainer>
    </Layout>

  )
}

export const Diy3Handheld: Page = {Page: Diy3HandheldPage, getUrl: () => "/diy/3-handheld"}

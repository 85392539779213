import {purple} from "@material-ui/core/colors"
import * as React from "react"
import {TransMdTypo} from "../../components/i18n/TransMdTypo"
import {ColorContainer, IndexSectionTitle, PaddedContainer} from "./IndexShared"
import {useTranslation} from "gatsby-plugin-react-i18next"

export const FeatureSection = () => {
  const {t} = useTranslation()
  return (
    <ColorContainer color={purple["900"]}>
      <PaddedContainer>
        <IndexSectionTitle>{t("home^The powerful retro console")}</IndexSectionTitle>
        <TransMdTypo>
          home^Recalbox Features
        </TransMdTypo>
      </PaddedContainer>
    </ColorContainer>
  )
}

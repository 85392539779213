import {Chip, Grid, Tooltip, Typography} from "@material-ui/core"
import {FaDownload} from "@react-icons/all-files/fa/FaDownload"
import {GrowGrid, ResponsiveContainer, SectionContainer, StarsImageContainer} from "../../../components/Shared"
import {usePatreon} from "./usePatreon"
import React, {useEffect, useState} from "react"
import {Link, useTranslation} from "gatsby-plugin-react-i18next"
import {Mixtape, WallPaper} from "../../../../../patreon-api/src/badges/Types"
import {FaLink} from "@react-icons/all-files/fa/FaLink"
import {GoodieCard, IncomingCard, LoadingCard} from "./goodies/GoodieCard"
import {PatronNav} from "./PatronNav";
import {Layout} from "../../../components/Layout";
import {useBreadcrumb} from "../../../hooks/useBreadcrumb";
import {ProfilePage} from "./ProfilePage";
import {Page} from "../../../components/PrivateRoutePage";

const WallpaperCard = (props: { wp: WallPaper }) => {
  const {wp} = props
  const link = getWpLink(wp.uid)
  return <GoodieCard image={wp.url} link={link}>
    <Typography>{wp.name}</Typography>
    <Grid container alignContent={"center"} alignItems={"center"}>
      <Grid item>
        <Link to={link}>
          <Tooltip title={"Open"} placement={"right"}>
            <FaLink/>
          </Tooltip>
        </Link>
      </Grid>
      <GrowGrid item/>
      <Grid item>
        <Chip size={"small"} label={"Wallpaper"} css={{marginRight: "0.5em"}}/>
      </Grid>
    </Grid>
  </GoodieCard>
}

const MixTapeCard = (props: { mixtape: Mixtape }) => {
  const {mixtape} = props
  const link = getMixtapeLink(mixtape.uid)

  return <GoodieCard image={mixtape.image} link={link} imgContain>
    <Typography>{mixtape.name}</Typography>
    <Grid container alignContent={"center"} alignItems={"center"}>
      <Grid item>
        <Link to={link}>
        <Tooltip title={"Open"} placement={"right"}>
          <FaLink/>
        </Tooltip>
        </Link>
      </Grid>
      <GrowGrid item/>
      <Grid item>
        <Chip size={"small"} label={"Mixtape"} css={{marginRight: "0.5em"}}/>
      </Grid>
    </Grid>
  </GoodieCard>
}

const getWpLink = (id: number) => `${GoodiesPage.getUrl()}wp/${id}`
const getMixtapeLink = (id: number) => `${GoodiesPage.getUrl()}mixtape/${id}`


export const Goodies = () => {
  const patreon = usePatreon()
  const {t} = useTranslation()
  const breadCrumb = useBreadcrumb(GoodiesPage)
  const [state, setState] = useState({
    loaded: false,
    wp: [] as WallPaper[],
    mixtapes: [] as (Mixtape | undefined)[],
  })

  useEffect(() => {
    patreon.getBadges().then((badges) => {
      const wp = badges.filter(b => b.unlocked).map(badge => badge.wallpaperUnlock).reduce((acc, cur) => acc.concat(cur), [])
      const mixtapes = badges.filter(b => b.unlocked).map(badge => badge.mixtapeUnlock).reduce((acc, cur) => acc.concat(cur), [])
      setState({
        ...state,
        loaded: true,
        wp,
        mixtapes
      })
    })
  }, [])

  return <Layout title={t("patreon^My Patron Area")}>
    <StarsImageContainer>
      <SectionContainer>
        {breadCrumb}
      </SectionContainer>
      <ResponsiveContainer>
        <PatronNav tab={"goodies"}/>
        <Grid container spacing={2}>
          {state.loaded ?
            <React.Fragment>
              {state.wp.map((wp, index) => {
                return <WallpaperCard wp={wp}/>
              })}
              {state.mixtapes.filter(m => m).map((mixtape, index) => {
                return <MixTapeCard mixtape={mixtape!}/>
              })}
              {[1, 2, 3, 4].map((wp, index) => {
                return <IncomingCard/>
              })}
            </React.Fragment>
            :
            <React.Fragment>
              {[1, 2, 3, 4, 5, 6].map((wp, index) => {
                return <LoadingCard/>
              })}
            </React.Fragment>
          }
        </Grid>
      </ResponsiveContainer>
    </StarsImageContainer>
  </Layout>
}

const getUrl = (type?: string, uid?: string) => `/patreon/patron/goodies/${type && uid ? `${type}/${uid}` : ""}`
export const GoodiesPage: Page = {
  Page: Goodies,
  getUrl,
  parentPage: () => ProfilePage,
  breadCrumb: {link: getUrl(), name: "Goodies"}
}

import {ThemeProvider as MuiThemeProvider} from "@material-ui/styles"
import * as React from "react"
import {Theme, ThemeProvider as EmotionThemeProvider} from "@emotion/react"


export const ThemeProvider = (props: { theme: Theme, children: React.ReactNode }) => {
  return (
    <MuiThemeProvider theme={props.theme}>
      <EmotionThemeProvider theme={props.theme}>
        {props.children}
      </EmotionThemeProvider>
    </MuiThemeProvider>
  )
}
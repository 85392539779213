import styled from "@emotion/styled"
import {Button, Grid, Typography} from "@material-ui/core"
import * as React from "react"
import {MdTypo} from "../../../components/i18n/TransMdTypo"

export const PaddedGrid = styled(Grid)(({theme}) => ({
  padding: theme.spacings.md
}))

export const PreTitle = (props: { children: any }) => (
  <MdTypo paragraph variant="body2" color="textSecondary">{props.children}</MdTypo>)

export const Title = (props: { children: any }) => (
  <Typography css={{marginBottom: "10px", marginTop: "20px"}} variant="h1">{props.children}</Typography>)

export const Title2 = (props: { children: any }) => (
  <Typography css={{marginBottom: "10px", marginTop: "40px"}} gutterBottom variant="h2">{props.children}</Typography>)

export const TextCenter = styled.div({
  textAlign: "center",
})

export const SelfCenterGrid = styled(Grid)(({theme}) => ({
  alignSelf: "center",
}))

export const CTAButton = styled(Button)((props: any) => ({
  margin: "auto"
}))

export const TextCenteredGrid = styled(Grid)({
  textAlign: "center"
})

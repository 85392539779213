import styled from "@emotion/styled"
import {Card, Grid, Hidden} from "@material-ui/core"
import {useI18next} from "gatsby-plugin-react-i18next"
import * as React from "react"
import {AppTableOfContents} from "./components/AppTableOfContent"
import {TransMd, TransMdTypo} from "../../components/i18n/TransMdTypo"
import {Layout} from "../../components/Layout"
import {SectionContainer, SectionTitle} from "../../components/Shared"

export const FaqEntry = styled(Card)(({theme}) => ({
  padding: theme.spacings.md,
  marginBottom: theme.spacings.xl,
  marginTop: theme.spacings.xl
}))

const Q = styled.span(({theme}) => ({
  color: theme.palette.secondary.main,
  fontSize: "3rem",
  marginRight: "1rem",
}))

const A = styled.span(({theme}) => ({
  fontSize: "3rem",
  marginRight: "1rem"
}))

const Question = styled.span(({theme}) => ({
  fontSize: "1.5rem",
  fontWeight: 500,
  lineHeight: "3rem",
  verticalAlign: "super"

}))

const FaqQuestion = (props: { children: string }) => {
  return (
    <div>
      <Q>Q.</Q>
      <Question>{props.children}</Question>
    </div>
  )
}
const FaqAnswer = (props: { children: React.ReactNode }) => {
  return (
    <div>
      <Grid container wrap={"nowrap"} alignContent={"center"}>
        <Grid item>
          <A>A.</A>
        </Grid>
        <Grid item>{props.children}</Grid>
      </Grid>
    </div>
  )
}

export const FaqResposiveContainer = styled.div`
  ${({theme}) => `
  @media (min-width: ${theme.breakpoints.values.md}px) {
      margin-right: ${theme.rightBar.width}px
  }
`};
`
export const FaqSummary = styled.div`
  ${({theme}) => `
  position:fixed;
  top: 0;
  right:0; 
  width: ${theme.rightBar.width}px;
  margin-top: ${theme.toolbar.height}px;
`};
`

const Faq = () => {
  const i18next = useI18next()
  const keys = Object.keys(i18next.i18n.getResourceBundle(i18next.language, "faq"))
  const items = keys.map((key) => {
    return {text: key, children: [], hash: key}
  })
  return (
    <Layout title={"F.A.Q."}>
      <FaqResposiveContainer>
        <SectionContainer>
          <SectionTitle>
            <TransMd>Frenquently Asked Questions about Recalbox</TransMd>
          </SectionTitle>
          {keys.map((key) => {
            return (
              <FaqEntry id={key}>
                <FaqQuestion>{key}</FaqQuestion>
                <FaqAnswer>
                  <TransMdTypo>{`faq^${key}`}</TransMdTypo>
                </FaqAnswer>
              </FaqEntry>
            )
          })}
        </SectionContainer>
      </FaqResposiveContainer>
      <Hidden smDown>
        <FaqSummary>
          <AppTableOfContents items={items}/>
        </FaqSummary>
      </Hidden>
    </Layout>
  )
}

export const FaqPage = {Page: Faq, getUrl: () => "/faq"}

import {Switch} from "@material-ui/core"

export const ThemeSwitch = (props: { lightMode: boolean, switch: () => void }) => {
  return (
    <Switch
      checked={props.lightMode}
      onChange={props.switch}
      color={"secondary"}
      inputProps={{"aria-label": "primary checkbox"}}/>
  )
}
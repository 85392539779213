import {CardContent, CardMedia, Grid} from "@material-ui/core"
import * as React from "react"
import {FullHeightCardActionArea, FullHeightCardWithImg, ResponsivePaddedGridItem} from "../../components/Shared"
import {IndexSectionTitle, PaddedContainer} from "./IndexShared"
import {TransMdTypo} from "../../components/i18n/TransMdTypo"
import createAnimationSvg from "../../data/images/index/create-animation.svg"
import manetteTutoSvg from "../../data/images/index/manette-tutos.svg"
import documentationSvg from "../../data/images/index/documentation.svg"

import styled from "@emotion/styled"
import {Link, useTranslation} from "gatsby-plugin-react-i18next"
import {Diy1Discover} from "../diy/Diy1Discover"
import {TutorialsPage} from "../tutorials/TutorialsPage"

const TutoCardMedia = styled(CardMedia)({
  height: "200px",
  img: {
    height: "100%"
  },
  object: {
    height: "100%"
  }
})
export const HowToBegin = () => {

  const {t} = useTranslation()

  return (
    <PaddedContainer>
      <IndexSectionTitle>{t("home^Where to begin?")}</IndexSectionTitle>

      <Grid container spacing={4}>
        <ResponsivePaddedGridItem>
          <FullHeightCardWithImg>
            <FullHeightCardActionArea component={Link} to={Diy1Discover.getUrl()}>
              <TutoCardMedia css={{height: "200px"}}>
                <object data={createAnimationSvg} type="image/svg+xml"/>
              </TutoCardMedia>
              <CardContent>
                <TransMdTypo variant={"h4"}>
                  home^1 - Create your Recalbox
                </TransMdTypo>
                <TransMdTypo variant="body2">
                  home^You should start with the first step
                </TransMdTypo>
              </CardContent>
            </FullHeightCardActionArea>
          </FullHeightCardWithImg>
        </ResponsivePaddedGridItem>

        <ResponsivePaddedGridItem>
          <FullHeightCardWithImg>
            <FullHeightCardActionArea component={Link} to={TutorialsPage.getUrl()}>
              <TutoCardMedia css={{padding: "2em"}}>
                <object data={manetteTutoSvg} type="image/svg+xml"/>
              </TutoCardMedia>
              <CardContent>
                <TransMdTypo variant={"h4"}>
                  home^2 - Tutorials
                </TransMdTypo>
                <TransMdTypo variant="body2">
                  home^Then the tutorials
                </TransMdTypo>
              </CardContent>
            </FullHeightCardActionArea>
          </FullHeightCardWithImg>
        </ResponsivePaddedGridItem>


        <ResponsivePaddedGridItem>
          <FullHeightCardWithImg>
            <FullHeightCardActionArea component='a' href='https://wiki.recalbox.com/'>
              <TutoCardMedia css={{paddingTop: "2em"}}>
                <object data={documentationSvg} type="image/svg+xml"/>
              </TutoCardMedia>
              <CardContent>
                <TransMdTypo variant={"h4"}>
                  home^3 - Documentation
                </TransMdTypo>
                <TransMdTypo variant="body2">
                  home^Finally documentation
                </TransMdTypo>
              </CardContent>
            </FullHeightCardActionArea>
          </FullHeightCardWithImg>
        </ResponsivePaddedGridItem>
      </Grid>
    </PaddedContainer>
  )
}

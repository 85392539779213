import {RRGBDOptions} from "./Types"
import {countriesCode, Country} from "./countries"
import {RRGBDItem, RRGBDItemToStock} from "../../../../../patreon-api/src/stock/StockApi"

const recalboxApiUrl = process.env.GATSBY_PATREON_API_URL || "https://api-patreon.recalbox.com"

const calculatePort = (options: RRGBDOptions, country: Country, quantity: number) => {
  let shippingCost = 0
  const baseWeight = 160
  const commandWeight = Object.entries(options).filter(o => o[1].selected).reduce((prev, current, index) => {
    return prev + current[1].weight
  }, baseWeight) * quantity

  if (country.country === "France") {
    shippingCost = 10
    if (commandWeight < 1000)
      shippingCost = 8
    if (commandWeight < 500)
      shippingCost = 7
    if (commandWeight < 250)
      shippingCost = 6
  } else if (country.continent === "EU") {
    // europe
    shippingCost = 18
    if (commandWeight < 1000)
      shippingCost = 12
    if (commandWeight < 500)
      shippingCost = 10
    if (commandWeight < 250)
      shippingCost = 8
  } else {
    // world && non UE european
    shippingCost = 30
    if (commandWeight < 1000)
      shippingCost = 20
    if (commandWeight < 500)
      shippingCost = 14
    if (commandWeight < 250)
      shippingCost = 12
  }
  return shippingCost
}

const shouldApplyScartDiscount = (options: RRGBDOptions, scartDiscountActivated: boolean) => {
  return scartDiscountActivated &&
    (Object.entries(options).some(item => item[0] === "case" && item[1].selected) ||
        Object.entries(options).some(item => item[0] === "casew" && item[1].selected) ||
        Object.entries(options).some(item => item[0] === "case400" && item[1].selected) ||
        Object.entries(options).some(item => item[0] === "case400w" && item[1].selected))
}

const calculateForPaypal = (quantity: number, options: RRGBDOptions, country: (Country | null), isPatron: boolean, extraDiscountPercent: number, scartDiscountActivated: boolean) => {
  let items_tax = 0
  let items_total = 0
  let item_discount = 0
  const patronDiscountPercent = isPatron ? 10 : 0
  const freeScart = shouldApplyScartDiscount(options, scartDiscountActivated)
  const items = Object.entries(options).filter(item => item[1].selected).map(item => {
    let price = item[1].price
    if(item[0] === "shieldedscart" && freeScart){
      price = 0
    }
    const ht = Math.round(price * 100 / 1.2) / 100
    const tax = price - ht
    items_tax += tax
    items_total += price
    if(item[0] === "board"){
      item_discount += price * ((patronDiscountPercent+extraDiscountPercent)/100)
    }else {
      item_discount += price * (patronDiscountPercent/100)
    }
    return {
      name: item[1].name,
      quantity: `${quantity}`,
      unit_amount: {value: `${ht.toFixed(2)}`, currency_code: "EUR"},
      tax: {value: `${tax.toFixed(2)}`, currency_code: "EUR"},
    }
  })
  const tax_grand_total = items_tax * quantity
  const discount_grand_total = item_discount * quantity
  const item_grand_total = items_total * quantity
  const item_grand_total_ht = items_total * quantity - (tax_grand_total)
  const shipping_grand_total = country ? calculatePort(options, country, quantity) : 0

  const amount = {
    value: `${(item_grand_total + shipping_grand_total - discount_grand_total).toFixed(2)}`,
    currency_code: "EUR",
    breakdown: {
      tax_total: {value: `${(tax_grand_total).toFixed(2)}`, currency_code: "EUR"},
      item_total: {value: `${(item_grand_total_ht).toFixed(2)}`, currency_code: "EUR"},
      shipping: {value: `${shipping_grand_total.toFixed(2)}`, currency_code: "EUR"},
      discount: {value: `${discount_grand_total.toFixed(2)}`, currency_code: "EUR"}
    },
  }
  return {
    items: [
      {items, amount}
    ],
    totalItems: items_total,
    grandTotal: item_grand_total,
    shipping: shipping_grand_total,
    grandTotalPlusShippingAndDiscount: item_grand_total + shipping_grand_total - discount_grand_total,
    canOrder: country && shipping_grand_total > 0 && items_total * quantity > 0,
    discount: discount_grand_total,
  }
}

const inStock = (options: RRGBDOptions) => {
  return options.board.stock > 0
}

const getStocks = (): Promise<RRGBDItemToStock> => {
  return fetch(`${recalboxApiUrl}/stock`)
    .then(response => response.json() as Promise<RRGBDItemToStock>)
    .catch(e => Promise.reject(e))
}

const validateShippingCountry = (countryCode: string | undefined, selectedCountry: (Country | null)) => {
  if (!countryCode || !selectedCountry) return false
  const codeFromList = countriesCode.find(country => country.name === selectedCountry.country)
  if (codeFromList) {
    return codeFromList.code === countryCode
  }
  return false
}

const orderSuccess = (quantity: number, options: { [key in RRGBDItem]: { selected: boolean } }, payerEmail: string, lang: string, patronEmail?: string) => {
  const orderStock = Object.entries(options)
    .filter(option => option[1].selected)
    .reduce((acc, previous, index) => ({...acc, [previous[0]]: {stock: quantity}}), {})

  // Mautic
  const data = new FormData()
  data.append("mauticform[formId]", "4")
  data.append("mauticform[formName]", "newrecalboxrgbdualclient")
  data.append("mauticform[lang]", lang)
  data.append("mauticform[email]", payerEmail)

  data.append("mauticform[messenger]", "1")

  fetch("https://market.recalbox.com/form/submit?formId=4", {
    mode: "cors",
    method: "POST",
    body: data,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    }
  })

  return fetch(`${recalboxApiUrl}/order`,
    {
      method: "POST",
      body: JSON.stringify({
        email: patronEmail,
        items: orderStock
      }),
      headers: {"Content-Type": "application/json"}
    })
    .then(response => response.json() as Promise<RRGBDItemToStock>)
    .catch(e => Promise.reject(e))
}

export const orderApi = {
  calculateForPaypal,
  inStock,
  getStocks,
  validateShippingCountry,
  orderSuccess,
  shouldApplyScartDiscount
}

import {CircularAvatar} from "./CircularAvatar"
import defaultAvatar from "../../data/images/profile/default-avatar.png"
import {User} from "../../api/plusApi"

export const UserAvatar = (props: { size: number, user?: User, url?: string, online?: boolean }) => {
  return (
    <CircularAvatar avatarUrl={props.user?.avatar?.url ?? props.url ?? defaultAvatar}
                    size={props.size} online={props.user?.online ?? props.online ?? false}/>
  )
}


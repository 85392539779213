import {graphql, useStaticQuery} from "gatsby"
import {MetadataQuery} from "../../graphql-types"

export const useSiteMetadata = () => {
  const {site} = useStaticQuery<MetadataQuery>(
    graphql`
    query Metadata {
      site {
        siteMetadata {
          siteUrl
          title
        }
      }
    } 
  `
  )
  return site!.siteMetadata
}


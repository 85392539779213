import {Box, Card, CardContent, CardMedia, Grid, Typography} from "@material-ui/core"
import styled from "@emotion/styled"
import {usePatreon} from "./usePatreon"
import React, {useEffect, useState} from "react"
import {TextCenter} from "../../diy/components/Shared"
import {BiCheckSquare} from "@react-icons/all-files/bi/BiCheckSquare"
import {BiSquare} from "@react-icons/all-files/bi/BiSquare"
import {ImImage} from "@react-icons/all-files/im/ImImage"
import {Link, useTranslation} from "gatsby-plugin-react-i18next"
import {Badge} from "../../../../../patreon-api/src/badges/Types"
import {ImMusic} from "@react-icons/all-files/im/ImMusic"
import {Page} from "../../../components/PrivateRoutePage";
import {ResponsiveContainer, SectionContainer, StarsImageContainer} from "../../../components/Shared";
import {PatronNav} from "./PatronNav";
import {Layout} from "../../../components/Layout";
import {useBreadcrumb} from "../../../hooks/useBreadcrumb";
import {MixtapePage} from "./goodies/MixtapePage";
import {WallpaperPage} from "./goodies/WallpaperPage";
import {ProfilePage} from "./ProfilePage";

const BadgeImage = styled.img({
  width: "40%",
  margin: "auto",
  img: {
    objectFit: "cover"
  }
})

export const BadgesComponent = () => {
  const patreon = usePatreon()
  const {t} = useTranslation()
  const breadCrumb = useBreadcrumb(BadgesPage)

  const [state, setState] = useState({badges: [] as Badge[]})
  useEffect(() => {
    patreon.getBadges().then((badges) => setState({...state, badges}))
  }, [])

  return <Layout title={t("patreon^Badges")}>
    <StarsImageContainer>
      <SectionContainer>
        {breadCrumb}
      </SectionContainer>
      <ResponsiveContainer>
        <PatronNav tab={"badges"}/>
        <Grid container spacing={2}>
          {state.badges.map(badge => (
            <Grid item xs={12} md={6} lg={4}>
              <Card>
                <CardMedia css={{textAlign: "center"}}><BadgeImage
                  src={badge.unlocked ? badge.image : badge.lockedImage}/></CardMedia>
                <CardContent css={{paddingTop: 0}}>
                  <TextCenter>
                    <Typography css={{fontWeight: 700}}>{badge.name}</Typography>
                    <Typography gutterBottom variant={"body2"}>{badge.description}</Typography>
                    <Box css={(theme) => ({
                      height: "0.2em",
                      width: "80%",
                      margin: "1em auto 1em auto",
                      backgroundColor: theme.palette.background.highlight
                    })}/>
                  </TextCenter>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant={"h6"} component={"text"} css={{fontSize:"1.1em"}}>Requirements:</Typography>
                      {badge.requirements.map(req => (
                        <Grid container wrap={"nowrap"} alignItems={"center"}>
                          <Grid item css={{paddingTop: "4px"}}>
                            {req.unlocked ? <BiCheckSquare css={(theme) => ({color: theme.palette.secondary.dark})}/> :
                              <BiSquare/>}
                          </Grid>
                          <Grid item>
                            <Typography variant={"body2"}> {req.name}</Typography>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant={"h6"} component={"text"} css={{fontSize:"1.1em"}}>Unlocks:</Typography>
                      {badge.wallpaperUnlock.map(wp => {
                        if (!wp) return <div></div>
                        return <Grid container wrap={"nowrap"} alignItems={"center"} spacing={1}>
                          <Grid item>
                            <ImImage css={(theme) => {
                              return badge.unlocked ? {color: "#FDBD2B"} : {}
                            }}/>
                          </Grid>
                          <Grid item>
                            {badge.unlocked ?
                              <Link to={WallpaperPage.getUrl(wp.uid)}>
                                <Typography variant={"body2"}>Wallpaper {wp.name}</Typography>
                              </Link>
                              :
                              <Typography variant={"body2"}>Wallpaper {wp.name}</Typography>
                            }
                              </Grid>
                        </Grid>
                      })}
                      {badge.mixtapeUnlock.map(mixtape => {
                        if (!mixtape) return <div></div>
                        return <Grid container wrap={"nowrap"} alignItems={"center"} spacing={1}>
                          <Grid item>
                            <ImMusic css={(theme) => {
                              return badge.unlocked ? {color: "#FDBD2B"} : {}
                            }}/>
                          </Grid>
                          <Grid item>
                            {badge.unlocked ?
                              <Link to={MixtapePage.getUrl(mixtape.uid)}>
                                <Typography variant={"body2"}> {mixtape.name}</Typography>
                              </Link>
                              :
                              <Typography variant={"body2"}> {mixtape.name}</Typography>
                            }
                          </Grid>
                        </Grid>
                      })}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))
          }
        </Grid>
      </ResponsiveContainer>
    </StarsImageContainer>
  </Layout>
}


const getUrl = () => `/patreon/patron/badges/`
export const BadgesPage: Page =
  {
    Page: BadgesComponent,
    getUrl,
    parentPage: () => ProfilePage,
    breadCrumb: {link: getUrl(), name: "Badges"}

  }

import {Link, useTranslation} from "gatsby-plugin-react-i18next"
import {DownloadStepper, Steps} from "./components/DownloadStepper"
import {TransMdTypo} from "../../components/i18n/TransMdTypo"
import {Button, Card, CardActionArea, CardContent, Grid, Typography} from "@material-ui/core"
import {Layout} from "../../components/Layout"
import {ResponsiveContainer} from "../../components/Shared"
import {ElementCard} from "./components/ElementCard"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import {DownloadCategoriesYaml, RecalboxChannelsYaml} from "../../../graphql-types"
import {FileNode} from "gatsby-plugin-image/dist/src/components/hooks"
import {BoardPage} from "./BoardPage"
import downloadOGImage from "../../data/images/opengraph/card-download.png"
import {TextCenter} from "../diy/components/Shared"
import {AllImagesPage} from "./AllImagesPage"

export const Category = (props: { pageContext: { categories: DownloadCategoriesYaml[], channel: RecalboxChannelsYaml } }) => {
  const categories = props.pageContext.categories.map(cat => ({
    gatsbyImage: getImage((cat.picture as any) as FileNode),
    ...cat
  }))
  const channelName = props.pageContext.channel.name!
  const {t} = useTranslation()
  return (
    <Layout title={`Recalbox Download - ${t("download^Category Selection")}`}
            description={t("download^Download and install Recalbox for your Rasbperry Pi, PC, Handheld and Odroid!")}
            imageUrl={downloadOGImage}>
      <DownloadStepper step={Steps.Category} channel={channelName}/>
      <ResponsiveContainer>
        <Card>
          <CardContent>
            <TransMdTypo variant={"h3"}>
              download^What are you installing Recalbox on?
            </TransMdTypo>

            <Grid container spacing={2}>
              {categories.map(category => {
                return <Grid item xs={6} md={3}>
                  <ElementCard>
                    <CardActionArea component={Link} to={BoardPage.getUrl(channelName, category.id)}>
                      <div>
                        <GatsbyImage image={category.gatsbyImage!} alt={category.name!}/>
                      </div>
                      <Typography>
                        {t(`download^${category.name}`)}
                      </Typography>
                    </CardActionArea>
                  </ElementCard>
                </Grid>
              })
              }
            </Grid>
          </CardContent>
        </Card>
        <TextCenter css={{marginTop: "2em"}}>
          <Button component={Link} variant={"outlined"} to={AllImagesPage.getUrl(channelName)}>
            {t("download^All images")}
          </Button>
        </TextCenter>
      </ResponsiveContainer>
    </Layout>)
}

export const CategoryPage = {Page: Category, getUrl: (channel: string) => `/download/${channel}/`}

export default CategoryPage.Page

import {Button, Grid} from "@material-ui/core"
import {Link, useTranslation} from "gatsby-plugin-react-i18next"
import * as React from "react"
import {Layout} from "../../components/Layout"
import {DiySteps} from "./components/DiySteps"
import {CTAButton, PaddedGrid, PreTitle, TextCenter, Title} from "./components/Shared"

import {Page} from "../../components/PrivateRoutePage"
import {TransMdTypo} from "../../components/i18n/TransMdTypo"
import {ResponsiveContainer} from "../../components/Shared"
import {CgArrowRight} from "@react-icons/all-files/cg/CgArrowRight"
import {Diy4Install} from "./Diy4Installation"
import diyAssembleMp4 from "../../data/videos/diy/assemble_original.mp4-processed.mp4"
import diyAssembleWebm from "../../data/videos/diy/assemble_original.mp4.webm"
import {DiyHardwareCard} from "./components/DiyHardwareCard"
import {graphql, useStaticQuery} from "gatsby"
import {Query} from "../../../graphql-types"
import {KitCard} from "./components/KitCard"

const Diy3TvPage = () => {
  const {t} = useTranslation()
  const kits = useStaticQuery<Query>(graphql`query KubiiKitTVQuery {
    allKubiiKitsYaml(filter: {type: {eq: "tv"}}) {
      edges {
        node {
          description
          id
          name
          url
          type
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
  `)
  return (

    <Layout title={t("diy^DIY Step 3 TV")}>
      <DiySteps active={2}/>
      <ResponsiveContainer>
        <PaddedGrid container spacing={3}>
          <Grid item xs={12} md={9} lg={9}>
            <PreTitle>
              {t("diy^Living room it will be!")}
            </PreTitle>
            <Title>{t("diy^Step 3 : The Hardware")}</Title>
          </Grid>
          <Grid xs={12} md={6} item>
            <DiyHardwareCard title={t("diy^Get the pack!")}
                             subtitle={t("diy^Get it at kubii")}>
              <Grid container spacing={2}>
                {kits.allKubiiKitsYaml.edges.map(edge => edge.node).map(kit =>
                  <Grid item xs={12} sm={6}>
                    <KitCard kit={kit}/>
                  </Grid>
                )}
              </Grid>
            </DiyHardwareCard>
          </Grid>
          <Grid xs={12} md={6} item>
            <DiyHardwareCard title={t("diy^Or do it yourself.")}
                             subtitle={t("diy^You will need the hardware")}>
              <TransMdTypo children={"diy^hardware for tv"}/>
              <TransMdTypo gutterBottom>diy^Missing anything, check kubii</TransMdTypo>
              <TextCenter css={{marginTop:"1em"}}>
                <Button target={"_blank"} href={"https://kubii.com/221-recalbox"} variant={"outlined"}>
                  {t("diy^Recalbox Shop at kubii")}
                </Button>
              </TextCenter>
            </DiyHardwareCard>
          </Grid>

        </PaddedGrid>
        <PaddedGrid container>
          <Grid item xs={12}>
            <PreTitle>
              {t("diy^You can now assemble your Recalbox!")}
            </PreTitle>
            <TransMdTypo variant={"h2"}>diy^Assemble</TransMdTypo>

            <video autoPlay muted loop css={{width: "100%", borderRadius: "1em"}}>
              <source src={diyAssembleMp4} type="video/mp4"/>
              <source src={diyAssembleWebm} type="video/webm"/>
            </video>
          </Grid>
          <CTAButton css={{marginTop: "1em"}} endIcon={<CgArrowRight/>} component={Link}
                     to={Diy4Install.getUrl(Diy3Tv.getUrl())} variant="outlined"
                     color="secondary">
            {t("diy^Step 4: Installation")}
          </CTAButton>
        </PaddedGrid>
      </ResponsiveContainer>
    </Layout>

  )
}

export const Diy3Tv: Page = {Page: Diy3TvPage, getUrl: () => "/diy/3-tv"}

import {Layout} from "../../components/Layout"
import {ResponsiveContainer} from "../../components/Shared"
import {DownloadBoardsYaml, RecalboxChannelsYaml} from "../../../graphql-types"
import {useTranslation} from "gatsby-plugin-react-i18next"
import {AllImagesComponent} from "./components/AllImagesComponent"


const AllImages = (props: { pageContext: { channel: RecalboxChannelsYaml, boards: DownloadBoardsYaml[] } }) => {
  const {channel, boards} = props.pageContext
  const {t} = useTranslation()
  return <Layout title={t("download^All images for Recalbox", {version: channel.version})}>
    <ResponsiveContainer>
      <AllImagesComponent channel={channel} boards={boards}/>
    </ResponsiveContainer>
  </Layout>
}


export const AllImagesPage = {
  Page: AllImages,
  getUrl: (channel: string) => `/download/${channel}/allimages/`
}

export default AllImagesPage.Page

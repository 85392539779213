import {KubiiKitsYaml} from "../../../../graphql-types"
import {FocusableCard} from "../../myrecalbox/components/FocusableCard"
import {CardActionArea, CardContent, CardMedia} from "@material-ui/core"
import {ExternalLink} from "../../../components/Shared"
import {GatsbyImage} from "gatsby-plugin-image"
import {TransMdTypo} from "../../../components/i18n/TransMdTypo"
import * as React from "react"

export const KitCard = (props: { kit: KubiiKitsYaml }) => {
  const {name, url, description, image} = props.kit
  return (
    <FocusableCard css={(theme) => ({backgroundColor: theme.palette.background.surface1})}>
      <CardActionArea component={ExternalLink}
                      href={url!}>
        <CardMedia>
          <GatsbyImage alt={name!}
                       image={image?.childImageSharp?.gatsbyImageData}/>
        </CardMedia>
        <CardContent>
          <TransMdTypo>
            {name}
          </TransMdTypo>
          <TransMdTypo variant={"body2"}>
            {description}
          </TransMdTypo>
        </CardContent>
      </CardActionArea>
    </FocusableCard>
  )
}

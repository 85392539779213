import styled from "@emotion/styled"
import {Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, Typography} from "@material-ui/core"
import React, {MouseEventHandler} from "react"
import {TutorialChips} from "./TutorialChips"
import {GatsbyImage} from "gatsby-plugin-image"
import {TutorialsYaml} from "../../../../graphql-types"

const TutoCard = styled(Card)(() => ({
  //height: "100%"
}))

// TODO style.div
const TutoCardMedia = styled(CardMedia)(() => ({
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  height: "200px"
}))

// TODO style.div
const TutoTitle = styled(Typography)(({theme}) => ({
  //height: theme.card.media.height
  fontSize: "1.2rem",
  fontWeight: 700,
  marginBottom: theme.spacings.xs,
}))

const TutorialDescription = styled.div(({theme}) => ({
  marginBottom: theme.spacings.xs,
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical"
}))

export const TutorialCard = (props: { tuto: TutorialsYaml, onClick: MouseEventHandler }) => {
  return <TutoCard>
    <CardActionArea onClick={props.onClick}>
      <TutoCardMedia>
        <GatsbyImage css={{height: "100%"}} image={props.tuto.thumb!.childImageSharp!.gatsbyImageData}
                     alt={props.tuto.title!} objectFit={"cover"}/>
      </TutoCardMedia>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <TutoTitle>{props.tuto.title}</TutoTitle>
          </Grid>
          <Grid item xs={12}>
            <TutorialDescription>
              {props.tuto.description}
            </TutorialDescription>
          </Grid>
          <Grid item xs={12} css={{alignSelf: "flex-end"}}>
            <CardActions>
              <TutorialChips tuto={props.tuto}/>
            </CardActions>
          </Grid>
        </Grid>
      </CardContent>
    </CardActionArea>

  </TutoCard>
}

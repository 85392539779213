import {createMuiTheme, ThemeOptions} from "@material-ui/core/styles"
import {responsiveFontSizes, Theme as MuiTheme} from "@material-ui/core"
import {overrides} from "./globalsCss"

declare module "@material-ui/core/styles/createPalette" {
  interface TypeBackground {
    default: string
    paper: string
    surface1: string
    highlight: string
  }
}
declare module "@material-ui/core/styles/createMuiTheme" {

  interface Theme {
    spacings: {
      xs: string,
      sm: string,
      md: string,
      lg: string,
      xl: string,
      xxl: string,
    },
    radius: {
      medium: string,
      big: string,
    },
    card: {
      media: {
        height: number,
      },
    },
    nav: {
      width: {
        xs: number,
        sm: number,
      },
    },
    rightBar: {
      width: number,
    },
    toolbar: {
      height: number,
    },
    customPalette: {
      patreon: {
        main: string
      }
    }
  }

  interface ThemeOptions {
    spacings?: {
      xs?: string,
      sm?: string,
      md?: string,
      lg?: string,
      xl?: string,
      xxl?: string,
    },
    radius?: {
      medium?: string,
      big?: string,
    },
    card?: {
      media?: {
        height?: number,
      },
    }
    nav?: {
      width?: {
        xs?: number,
        sm?: number,
      },
    },
    rightBar?: {
      width?: number,
    },
    toolbar?: {
      height?: number,
    },
    customPalette?: {
      patreon?: {
        main?: string
      }
    }
  }
}

declare module "@emotion/react" {
  export interface Theme extends MuiTheme {
  }
}

const lightThemePartial: ThemeOptions = {
  typography: {
    fontSize: 11,
    fontFamily: "Rajdhani",
    h1: {
      fontSize: "3rem",
      fontWeight: 600,
    },
    h2: {
      fontSize: "2.5rem",
      fontWeight: 600,
    },
    h3: {
      fontSize: "2.125rem",
      fontWeight: 600,
    },
    h4: {
      fontSize: "1.8rem",
      fontWeight: 600,
    },
    h5: {
      fontSize: "1.6rem",
      fontWeight: 600,
    },
    h6: {
      fontSize: "1.4rem",
      fontWeight: 600,
    },
    body1: {
      fontSize: "1.1rem",
      fontFamily: "Open Sans",
    },
    body2: {
      fontSize: "1.1rem",
      fontFamily: "Open Sans",
    },
    button: {
      fontSize: "1.1rem",
    },
  },
  palette: {
    type: "light",
    primary: {
      main: "#7750f8",
    },
    secondary: {
      main: "#40d04f",
    },
    background: {
      paper: "#f8f8fb",
      default: "#fff",
      surface1: "#fff",
      highlight: "#fff",
    },

  },
  shape: {
    borderRadius: 10,
  },
  overrides: overrides("#40d04f"),

  spacings: {
    xs: "0.3rem",
    sm: "0.5rem",
    md: "1rem",
    lg: "1.5rem",
    xl: "2rem",
    xxl: "8rem",
  },
  radius: {
    medium: "0.5rem",
    big: "1rem",
  },
  card: {
    media: {
      height: 150
    }
  },
  nav: {
    width: {
      xs: 72,
      sm: 240,
    }
  },
}

const darkThemePartial: ThemeOptions = {
  ...lightThemePartial,
  palette: {
    type: "dark",
    text: {
      primary: "rgba(255, 255, 255, 0.75)",
      secondary: "rgba(255, 255, 255, 0.6)",
      disabled: "rgba(255, 255, 255, 0.3)"
    },
    primary: {
      main: "#7750f8",
    },
    secondary: {
      main: "#40d04f",
    },
    background: {
      paper: "#1d2333",
      default: "#161b28",
      surface1: "#21283B",
      highlight: "#424d69",
    }
  },
  customPalette: {
    patreon: {
      main: "#FF424D"
    }
  },
  overrides: overrides("#40d04f"),
}

const recalboxThemePartial: ThemeOptions = {
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  toolbar: {
    height: 0,
  },
  nav: {
    width: {
      xs: 72,
      sm: 72,
    }
  },
}

const websiteThemePartial: ThemeOptions = {
  rightBar: {
    width: 300,
  },
  toolbar: {
    height: 64,
  },
}


const recalboxThemeLight = createMuiTheme({
  ...lightThemePartial,
  ...recalboxThemePartial,
})

const recalboxThemeDark = createMuiTheme({
  ...darkThemePartial,
  ...recalboxThemePartial,
})

const websiteThemeLight = responsiveFontSizes(createMuiTheme({
  ...lightThemePartial,
  ...websiteThemePartial,
}))

const websiteThemeDark = responsiveFontSizes(createMuiTheme({
  ...darkThemePartial,
  ...websiteThemePartial,
}))

export const getTheme = (isRecalboxExperience: boolean, isLight: boolean) => {
  if (isRecalboxExperience) {
    return isLight ? recalboxThemeLight : recalboxThemeDark
  }
  return isLight ? websiteThemeLight : websiteThemeDark
}

export const legacyTheme = createMuiTheme({
  ...darkThemePartial,
  ...recalboxThemePartial,
  palette: {
    ...darkThemePartial.palette,
    background: {
      paper: "#314051",
      default: "#3E4F61",
      surface1: "#C3C5C8",
      highlight: "#515D6C",
    },
  }
})

import {Grid} from "@material-ui/core"
import {Link, useTranslation} from "gatsby-plugin-react-i18next"
import * as React from "react"
import {Layout} from "../../components/Layout"
import {DiySteps} from "./components/DiySteps"
import {CTAButton, PaddedGrid, PreTitle, Title} from "./components/Shared"

import {Page} from "../../components/PrivateRoutePage"
import {ResponsiveContainer} from "../../components/Shared"
import {MdTypo, TransMdTypo} from "../../components/i18n/TransMdTypo"
import diyInstallMp4 from "../../data/videos/diy/install_original.mp4-processed.mp4"
import diyInstallWebm from "../../data/videos/diy/install_original.mp4.webm"
import {Diy3Tv} from "./Diy3Tv"
import {CgArrowRight} from "@react-icons/all-files/cg/CgArrowRight"
import * as queryString from "query-string"
import {useLocation} from "@reach/router"
import {ChannelPage} from "../download/ChannelPage"
import {useI18nLink} from "../../components/i18n/I18nLink"

const Diy4InstallPage = () => {
  const {t} = useTranslation()
  const location = useLocation()
  const i18NLink = useI18nLink()
  const hardwareLink = queryString.parse(location.search)?.hardware as string ?? Diy3Tv.getUrl()
  return (
    <Layout title={t("diy^DIY Step 4")}>
      <DiySteps active={3} linkToHardware={hardwareLink}/>
      <ResponsiveContainer>
        <PaddedGrid container spacing={3}>
          <Grid item xs={12}>
            <PreTitle>
              {t("diy^You hardware is assembled, let's install Recalbox")}
            </PreTitle>
            <Title>{t("diy^Step 4: Installation")}</Title>
          </Grid>
          <Grid item xs={12}>
            <MdTypo>{t("diy^How to install", {link: `[recalbox.com/download](${i18NLink.getLink(ChannelPage.getUrl())})`})}</MdTypo>
          </Grid>
        </PaddedGrid>
        <PaddedGrid container>
          <Grid item xs={12}>
            <PreTitle>
              {t("diy^Only a few minutes")}
            </PreTitle>
            <TransMdTypo variant={"h2"}>diy^The installation</TransMdTypo>

            <video autoPlay muted loop css={{width: "100%", borderRadius: "1em"}}>
              <source src={diyInstallMp4} type="video/mp4"/>
              <source src={diyInstallWebm} type="video/webm"/>
            </video>
          </Grid>
          <CTAButton css={{marginTop: "1em"}} endIcon={<CgArrowRight/>} component={Link} to={ChannelPage.getUrl()}
                     variant="outlined"
                     color="secondary">
            {t("diy^Download Recalbox")}
          </CTAButton>
        </PaddedGrid>
      </ResponsiveContainer>
    </Layout>

  )
}


export const Diy4Install: Page = {
  Page: Diy4InstallPage,
  getUrl: (linkToHardware: string) => `/diy/4-install?hardware=${linkToHardware}`
}

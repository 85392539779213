import * as React from "react"
import {Badge, List, ListItem, ListItemIcon, ListItemText, Theme} from "@material-ui/core"
import {GoLinkExternal} from "@react-icons/all-files/go/GoLinkExternal"
import {NavigationBadge, NavigationMenu} from "./Navigation"
import styled from "@emotion/styled"
import {Link} from "../../components/i18n/I18nLink"
import {useTranslation} from "gatsby-plugin-react-i18next"
import {HiOutlineMenu} from "@react-icons/all-files/hi/HiOutlineMenu"
import {WithTwoPassRendering} from "../../components/WithTwoPassRendering"


const NavBadge = styled(Badge)((props: AlignCenterProps) => ({
  margin: `${props.alignCenter && "0 auto"}`
}))

type AlignCenterProps = { alignCenter: boolean }
type BadgeProps = React.PropsWithChildren<{ badge?: NavigationBadge }> & AlignCenterProps;

const Icon = styled.span((props: any) => ({
  color: "inherit",
  fontSize: "24px",
  width: "24px",
  height: "24px",
  lineHeight: "0px",
  margin: `${props.alignCenter && "0 auto"}`,
  svg: {
    verticalAlign: "middle"
  }
}))


export const BadgeIcon = (props: BadgeProps) => {
  return props.badge ? (
    <NavBadge alignCenter badgeContent={props.badge.badge} color={props.badge.color || "primary"}>
      <Icon alignCenter>{props.children}</Icon>
    </NavBadge>
  ) : (
    <Icon alignCenter>{props.children}</Icon>
  )
}

export const RecalboxMenuLink = styled.div(({theme}: any) => ({
  width: "100%",
  borderRadius: theme.shape.borderRadius,
  color: "#616a82",
  fill: "#616a82",
  "&.active, &.active:hover, &.active:focus-within": {
    color: "white",
    fill: "white",
    backgroundColor: theme.palette.secondary.main,
    boxShadow: `4px 7px 12px 0 ${theme.palette.secondary.main}30`
  },
  ":hover,:focus-within": {
    color: theme.palette.secondary.main,
    fill: theme.palette.secondary.main,
    backgroundColor: theme.palette.background.highlight,
  },
  "& > a ": {
    width: "100%"
  }
}))

const createLinkProps = (menu: NavigationMenu) => {
  return menu.to
    ? {
      component: RecalboxMenuLink.withComponent(Link),
      to: menu.to,
      activeClassName: "active",
      partiallyActive: (menu.to !== "/")
    }
    : {component: RecalboxMenuLink.withComponent("a"), href: menu.href, target: "_blank"}
}

const ListItemStyled = styled(ListItem)((props) => ({
  width: "100%",
  height: "48px",
  marginBottom: "12px",
  justifyContent: "center"
})) as unknown as typeof ListItem

const ListItemIconStyled = styled(ListItemIcon)(({theme}) => ({
  color: "inherit",

}))

const ListItemTextStyled = styled(ListItemText)(({theme}) => ({
  span: {
    fontSize: "1rem",
    fontFamily: theme.typography.fontFamily
  }
}))

const NavEntry = (props: { variant?: "small", menu: NavigationMenu, badge?: string  }) => {
  const {variant, menu, badge} = props
  const {t} = useTranslation()
  const linkProps = createLinkProps(menu)
  const badgedMenu = <BadgeIcon alignCenter={false} badge={badge ? {badge:badge}: menu.badge}>
    {menu.icon}
  </BadgeIcon>
  return (
    /*<Tooltip title={"Coucou"} >*/
    <ListItemStyled button {...linkProps}>
      <ListItemIconStyled>
        {badgedMenu}
      </ListItemIconStyled>
      {variant != "small" && <ListItemTextStyled primary={t(menu.title)}/>}
      {variant != "small" && menu.href && <GoLinkExternal/>}
    </ListItemStyled>
    /*
        </Tooltip>
    */
  )
}

const ResponsiveList = styled(List)`
  ${(props: { theme?: Theme, variant?: "small" }) => `
  padding: 12px;
  width: ${props.variant ? props.theme?.nav.width.xs : props.theme?.nav.width.sm}px;
`}
`

const NavListRaw = (props: { variant?: "small", menus: NavigationMenu[], logged: boolean, switchMenuOpen: () => {}, blogToRead:number }) => {
  return (
    <React.Fragment>
      <ResponsiveList variant={props.variant}>
        {props.switchMenuOpen ?
          <ListItemStyled button component={RecalboxMenuLink} onClick={props.switchMenuOpen}>
            <ListItemIconStyled >
              <BadgeIcon alignCenter={false}>
                <HiOutlineMenu/>
              </BadgeIcon>
            </ListItemIconStyled>
          </ListItemStyled>
          :
          <div css={{height: "60px"}}></div>
        }
        {props.menus.filter((menu) => (!menu.logged))
          .filter(menu => menu.disabled !== true)
          .map((menu) => {
          if(menu.id === "blog"){
            return <NavEntry variant={props.variant} key={menu.title} menu={menu} badge={props.blogToRead ? `${props.blogToRead}`: undefined}/>
          }
          return <NavEntry variant={props.variant} key={menu.title} menu={menu}/>
        })}
      </ResponsiveList>
    </React.Fragment>
  )
}

export const NavList = WithTwoPassRendering(NavListRaw)

import React, {useEffect, useState} from "react"


export const WithTwoPassRendering = (WrappedComponent: any) => (props: any) => {
  const {children, ...rest} = props

  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [setIsClient])

  return (
    <WrappedComponent {...rest} key={isClient}>
      {children}
    </WrappedComponent>
  )
}
import Parse from "parse"
import * as parseApi from "./parseApi"
import {BlogPostDraftEntity, BlogPostEntity, PlatformLanguage} from "./ParseObjects"

/* Posts */


export const findPostById = async (postId: string): Promise<BlogPostEntity | undefined> => {
  const query = new Parse.Query(BlogPostEntity).include("wroteBy")
  query.equalTo("objectId", postId)
  return await query.first()
}

export const findPostByI18nId = async (postI18nId: string): Promise<BlogPostEntity[]> => {
  const query = new Parse.Query(BlogPostEntity).include("wroteBy")
  query.equalTo("i18nId", postI18nId)
  return query.find()
}


/* Drafts */
export const findAllDrafts = async (): Promise<BlogPostDraftEntity[]> => {
  const query = new Parse.Query(BlogPostDraftEntity).include("wroteBy")
  return await query.find()
}

export const findPostDraftsByI18nId = async (postI18nId: string): Promise<BlogPostDraftEntity[]> => {
  const query = new Parse.Query(BlogPostDraftEntity).include("wroteBy")
  query.equalTo("i18nId", postI18nId)
  return query.find()
}

export const newTranslationDraft = async (postI18nId: string, lang: PlatformLanguage): Promise<BlogPostDraftEntity> => {
  const query = new Parse.Query(BlogPostDraftEntity)
  const currentUser = await parseApi.currentRecalboxUser()
  query.equalTo("i18nId", postI18nId)
  query.equalTo("original", true)
  const original = await query.first()
  if (original) {
    return new BlogPostDraftEntity({...original.attributes, translatedBy: currentUser, language: lang}).save()
  } else {
    throw new Error("Unable to find the post")
  }
}

export const newPostDraft = async (lang: PlatformLanguage): Promise<BlogPostDraftEntity> => {
  const currentUser = await parseApi.currentRecalboxUser()
  const now = new Date()
  return new BlogPostDraftEntity({
    language: lang,
    images: [],
    wroteBy: currentUser,
    original: true,
    i18nId: now.toISOString().slice(0, 10) + "-",
    publishDate: now,
    content: "",
    tags: [],
    state: "draft",
    title: "Title",
    jumbo: undefined,
    translatedBy: undefined
  })
}


export const resetPostDraft = async (draft: BlogPostDraftEntity): Promise<BlogPostDraftEntity> => {
  const currentUser = await parseApi.currentRecalboxUser()
  const query = new Parse.Query(BlogPostEntity)
  query.equalTo("i18nId", draft.attributes.i18nId)
  const publishedPosts = await query.find()
  const publishedInDraftLanguage = publishedPosts.find(p => p.attributes.language === draft.attributes.language)
  if (publishedInDraftLanguage) {
    // I have a post that exists in the desired language, just copy it to the draft
    draft.set({...publishedInDraftLanguage.attributes, state: "draft", translatedBy: currentUser})
    return draft
  } else {
    throw new Error("Unable to reset the post draft, no published post found with draft language")
  }
}

export const publishDraft = async (draft: BlogPostDraftEntity): Promise<BlogPostDraftEntity> => {
  const query = new Parse.Query(BlogPostEntity)
  query.equalTo("i18nId", draft.attributes.i18nId)
  query.equalTo("language", draft.attributes.language)
  const publishedPost = await query.first()
  if (publishedPost) {
    // I have a post that exists in the desired language, just replace it with the draft
    publishedPost.set({...draft.attributes, state: "published"})
    return publishedPost.save()
  } else {
    return new BlogPostEntity({...draft.attributes, state: "published"}).save()
  }
}

import styled from "@emotion/styled"
import {Card, CardActionArea, CardMedia, Grid, Typography} from "@material-ui/core"
import * as React from "react"
import {AnchorHTMLAttributes, PropsWithChildren, ReactElement} from "react"
import Img from "gatsby-image"
import background from "../data/images/index/background.jpg"


export const ResponsiveContainer = styled.div(({theme}) => ({
  position: "relative",
  ["@media (min-width: 1500px)"]: {
    maxWidth: `${1500 - theme.nav.width.sm - 20}px`
  },
  margin: "auto",
  padding: `${theme.spacings.lg}`,
  [`@media (max-width: ${theme!.breakpoints.values.sm}px)`]: {
    padding: `${theme.spacings.lg} ${theme.spacings.sm} 0 ${theme.spacings.sm}`,
  }
}))
export const GrowGrid = styled(Grid)(({theme}) => ({
  flexGrow: 1
}))

export const PaddedGridItem = styled(Grid)`
  ${({theme}) => `
  padding: ${theme.spacings.md} ${theme.spacings.md} ${theme.spacings.md} 0
  `}
`

export const ResponsivePaddedGridItem = (props: { children: ReactElement }) => {
  return (<PaddedGridItem item xs={12} sm={6} lg={4}>
    {props.children}
  </PaddedGridItem>)
}

// Section
export const SectionContainer = styled(ResponsiveContainer)(({theme}) => ({
  paddingTop: theme.spacing(5),
}))

export const Highlight = styled.span(({theme}) => ({
  fontWeight: "bold",
  color: theme.palette.secondary.main
}))

// New elements for Marketplace that should be used everywhere
export const SectionTitle = (props: { component?: "h1" | "h2", className?: string } & PropsWithChildren<any>) => {
  return <Typography className={props.className} css={{fontSize: "1.625rem", fontWeight: 700}}
                     component={props.component ?? "h2"} children={props.children}/>
}

export const SectionPreTitle = styled(Typography)(({theme}) => ({
  fontSize: "1rem",
  fontWeight: 600,
  color: theme.palette.text.secondary
}))


export const FixedHeightImage = styled(Img)({
  height: "100%"
})

export const FixedHeightImg = styled.img({
  height: "100%"
})

export const FullHeightCardWithImg = styled(Card)({
  height: "100%",
  img: {
    height: "100%",
    width: "100%"
  }
})
export const FullHeightCard = styled(Card)({
  height: "100%",
})

export const FullHeightCardActionArea = styled(CardActionArea)({
  height: "100%",
}) as typeof CardActionArea

export const FullHeightCardMedia = styled(CardMedia)({
  height: "100%",
})

export const CoverImage = styled.img({
  objectFit: "cover",
  width: "100%",
})

export const FullHeightCoverImage = styled.img({
  objectFit: "cover",
  width: "100%",
  height: "100%",
})

export const ContainImage = styled.img({
  objectFit: "contain",
  width: "100%",
  maxHeight: "100%"
})

export const ContainCenteredImage = styled.img({
  objectFit: "contain",
  width: "100%",
  maxHeight: "100%",
  position: "absolute",
  transform: "translateX(-50%) translateY(-50%)",
  top: "50%",
  left: "50%",
})

export const FullHeightGrid = styled(Grid)({
  height: "100%",
  overflow: "hidden"
})

export const Focusable = styled.a(({theme}) => ({
  transition: "transform ease 0.5s",
  border: "3px solid #00000000",
  borderRadius: "5px",
  boxSizing: "inherit",
  ":focus,:focus-within,:hover": {
    transform: "scale(1.1)",
    border: `3px solid ${theme.palette.secondary.main}`
  }
}))

export const ExternalLink = (props: AnchorHTMLAttributes<any>) => {
  return <a target={"_blank"} {...props}>{props.children}</a>
}

export const UnstyledExternalLink = styled(ExternalLink)(({theme}) => ({
  color: "inherit",
  fontWeight: "inherit",
  textTransform: "none",
  ":hover": {
    color: "inherit",
  }
}))

export const NewExternalLink = styled(ExternalLink)(({theme}) => ({
  color: "inherit",
  textTransform: "none",
  transition: "all 0.3s",
  ":hover": {
    color: theme.palette.secondary.main,
  }
}))

export const StarsImageContainer = styled.div((props) => ({
  backgroundImage: `url(${background})`,
  backgroundRepeat: "repeat-x",
  backgroundColor: "#081120",
  paddingBottom: "2em",
}))

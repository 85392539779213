import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@material-ui/core"
import {ExternalLink, ResponsiveContainer, SectionContainer, StarsImageContainer} from "../../../../components/Shared"
import {usePatreon} from "../usePatreon"
import React, {useEffect, useState} from "react"
import {Link, useTranslation} from "gatsby-plugin-react-i18next"
import {useLocation} from "@reach/router"
import {Badge, Mixtape} from "../../../../../../patreon-api/src/badges/Types"
import styled from "@emotion/styled";
import {PatronNav} from "../PatronNav";
import {Layout} from "../../../../components/Layout";
import {useBreadcrumb} from "../../../../hooks/useBreadcrumb";
import {GoodiesPage} from "../GoodiesPage";
import {Page} from "../../../../components/PrivateRoutePage";
import {ImMusic} from "@react-icons/all-files/im/ImMusic";
import {BadgesPage} from "../BadgesPage";
import {AiOutlineUnlock} from "@react-icons/all-files/ai/AiOutlineUnlock";
import {TransMdTypo} from "../../../../components/i18n/TransMdTypo";
import {AiFillStar} from "@react-icons/all-files/ai/AiFillStar";

const FullwidthImage = styled.img({
  maxWidth: "100%",
  maxHeight: "70vh"
})

const getFromUrl = (path: string) => {
  const mt = new RegExp("/mixtape/([0-9]+)$")
  const match = path.match(mt)

  if (match && match[1]) {
    return parseInt(match[1])
  }
  return null
}

const MixtapeComponent = () => {
  const patreon = usePatreon()
  const loc = useLocation()
  const {t} = useTranslation()
  const breadCrumb = useBreadcrumb(GoodiesPage)
  const [state, setState] = useState({
    currentMixtape: undefined as Mixtape | undefined,
    unlockBadge: undefined as Badge | undefined
  })

  useEffect(() => {
    const currentGoodie = getFromUrl(loc.href)
    if (currentGoodie !== null) {
      patreon.getBadges().then((badges) => {
        const mt = badges.filter(b => b.unlocked)
          .map(badge => badge.mixtapeUnlock)
          .reduce((acc, cur) => acc.concat(cur), [])
          .find(mt => mt?.uid === currentGoodie)
        const unlockBadge = badges.filter(b => b.unlocked)
          .find(badge => badge.mixtapeUnlock.find(mt => mt?.uid === currentGoodie))
        setState({
          ...state,
          currentMixtape: mt,
          unlockBadge: unlockBadge,
        })
      })
    }
  }, [])

  return <Layout title={t("patreon^Mixtape")}>
    <StarsImageContainer>
      <SectionContainer>
        {breadCrumb}
      </SectionContainer>
      <ResponsiveContainer>
        <PatronNav tab={"goodies"}/>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant={"h2"}>
                      {state.currentMixtape?.name}
                    </Typography>
                    <TransMdTypo>patreon^This Mixtape will be played automatically on your Recalbox when you navigate
                      through the menus!</TransMdTypo>
                  </Grid>
                  <Grid item xs={4}>
                    <CardMedia>
                      <FullwidthImage src={state.currentMixtape?.image}></FullwidthImage>
                    </CardMedia>
                    {state.unlockBadge &&
                    <Typography css={{marginTop: "1em"}}><AiOutlineUnlock/> Unlocked by <Link
                      to={BadgesPage.getUrl()}>{state.unlockBadge.name}</Link> badge!</Typography>
                    }
                    <Typography css={{marginTop: "1em"}}><AiFillStar/> Thanks to <ExternalLink
                      href={"https://gamechops.com/"} target={"_blank"}>gamechops.com</ExternalLink> for all those
                      fabulous musics!</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <List>
                      {state.currentMixtape?.playlist.map(track => (
                        <ListItem>
                          <ListItemIcon><ImMusic/></ListItemIcon>
                          <ListItemText><strong>{track.name}</strong> from <ExternalLink href={track.link}
                                                                                         target={"_blank"}>{track.album}</ExternalLink>
                          </ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </ResponsiveContainer>
    </StarsImageContainer>
  </Layout>
}

const getUrl = (uid: string) => `/patreon/patron/goodies/mixtape/${uid}`
export const MixtapePage: Page =
  {
    Page: MixtapeComponent,
    getUrl,
    parentPage: () => GoodiesPage,
  }

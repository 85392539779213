import {Box, CardActionArea, CardContent, Grid, Typography} from "@material-ui/core"
import {useTranslation} from "gatsby-plugin-react-i18next"
import * as React from "react"
import {Layout} from "../../components/Layout"
import {DiySteps} from "./components/DiySteps"
import {PaddedGrid, PreTitle, Title} from "./components/Shared"
import {FocusableCard} from "../myrecalbox/components/FocusableCard"
import tvImage from "../../data/images/diy/xp-tv.jpg"
import handheldImage from "../../data/images/diy/xp-handheld.jpg"
import pcImage from "../../data/images/diy/xp-pc.jpg"
import {FullHeightCardMedia, FullHeightCoverImage, ResponsiveContainer} from "../../components/Shared"
import {Diy3Tv} from "./Diy3Tv"
import {Link} from "../../components/i18n/I18nLink"
import {Page} from "../../components/PrivateRoutePage"
import {Diy3PC} from "./Diy3PC"
import {Diy3Handheld} from "./Diy3Handheld"


const cardHeight = "400px"
const Diy2ExperiencePage = () => {
  const {t} = useTranslation()
  const experiences = [{
    title: t("diy^TV"),
    subtitle: t("diy^Recalbox in your living room"),
    image: tvImage,
    to: Diy3Tv.getUrl()
  }, {
    title: t("diy^PC"),
    subtitle: t("diy^Recalbox on your PC"),
    image: pcImage,
    to: Diy3PC.getUrl()
  }, {
    title: t("diy^Handheld"),
    subtitle: t("diy^Recalbox everywhere!"),
    image: handheldImage,
    to: Diy3Handheld.getUrl()
  }]
  return (

    <Layout title={t("diy^DIY Step 2")}>
      <DiySteps active={1}/>
      <ResponsiveContainer>
        <PaddedGrid container>
          <Grid item xs={12} md={9} lg={9}>
            <PreTitle>
              {t("diy^Either TV, PC or handheld?")}
            </PreTitle>
            <Title>{t("diy^Step 2: Choose your experience")}</Title>
          </Grid>
          <Grid xs={12} container item spacing={3}>
            {experiences.map((xp) => {
              return (
                <Grid item xs={12} sm={6} md={4}>
                  <FocusableCard>
                    <CardActionArea css={{height: cardHeight, position: "relative"}} component={Link} to={xp.to}>
                      <FullHeightCardMedia>
                        <FullHeightCoverImage src={xp.image} alt={"Gaming room"}/>
                      </FullHeightCardMedia>
                      <CardContent css={{
                        padding: 0,
                        textAlign: "center",
                        position: "absolute",
                        width: "100%",
                        top: "50%",
                        transform: "translate(0%,-50%)",
                      }}>
                        <Box css={(theme) => ({
                          backgroundColor: `${theme.palette.background.surface1}bb`,
                          padding: "1em"
                        })}>
                          <Typography variant={"h3"}>{xp.title}</Typography>
                          <Typography variant={"body2"}>{xp.subtitle}</Typography>
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </FocusableCard>
                </Grid>
              )
            })}
          </Grid>
        </PaddedGrid>
      </ResponsiveContainer>
    </Layout>
  )
}


export const Diy2Experience: Page = {Page: Diy2ExperiencePage, getUrl: () => "/diy/2-experience"}

import {green} from "@material-ui/core/colors"
import {Button} from "@material-ui/core"
import * as React from "react"
import {Link, useTranslation} from "gatsby-plugin-react-i18next"
import {Diy1Discover} from "../diy/Diy1Discover"
import consoleSVG from "../../data/images/index/consolecontrollersimple.svg"
import styled from "@emotion/styled"
import {TransMdTypo} from "../../components/i18n/TransMdTypo"
import {ColorContainer, IndexSectionTitle, PaddedContainer} from "./IndexShared"

const BGImage = styled.img((props) => ({
  position: "absolute",
  bottom: "2em",
  left: "10%",
  width: "80%",
  [`@media (min-width: ${props.theme!.breakpoints.values.lg}px)`]: {
    left: "20%",
    width: "60%",
    bottom: "1em",
  }
}))


export const SimplicitySection = () => {
  const {t} = useTranslation()
  return (
    <ColorContainer color={green["700"]} css={{position: "relative"}}>
      <BGImage src={consoleSVG}/>
      <PaddedContainer>
        <IndexSectionTitle>{t("home^It has never been so simple")}</IndexSectionTitle>
        <TransMdTypo>
          home^Simple and accessible
        </TransMdTypo>
        <Button css={{marginTop: "2em"}} variant={"contained"} component={Link} to={Diy1Discover.getUrl()}>
          {t("home^Go to step 1")}
        </Button>
      </PaddedContainer>

    </ColorContainer>
  )
}

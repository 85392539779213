import styled from "@emotion/styled"
import {Box, Chip} from "@material-ui/core"
import React from "react"
import {IoMdTimer} from "@react-icons/all-files/io/IoMdTimer"
import {Tutorial} from "../TutorialPage"

const TutoChip = styled(Chip)`
  ${({theme}) => `
  margin-right: ${theme.spacings.sm};
  margin-top: ${theme.spacings.sm};
`}
`

const TutorialDuration = (props: { duration: string }) => {
  return (
    <TutoChip css={{float: "right", marginRight: 0}} icon={<IoMdTimer/>} color="default" size="small"
              label={props.duration}/>
  )
}
export const TutorialChips = (props: { tuto: Tutorial }) => {
  return (<Box>
      <TutoChip color="primary" size="small" label={props.tuto.category}/>
      {props.tuto.tags?.map((tag, tagIndex) => {
        return <TutoChip color="secondary" size="small" label={tag}/>
      })}
      {props.tuto.duration &&
      <TutorialDuration duration={props.tuto.duration}/>
      }
    </Box>
  )
}
import {Layout} from "../../components/Layout"
import {Page} from "../../components/PrivateRoutePage"
import {useTranslation} from "gatsby-plugin-react-i18next"
import {ResponsiveContainer} from "../../components/Shared"
import {Card, CardContent} from "@material-ui/core"
import {TransMdTypo} from "../../components/i18n/TransMdTypo"
import React from "react"


const Legal = () => {
  const {t} = useTranslation()
  return <Layout title={t("legal^Terms of use")}>
    <ResponsiveContainer>
      <Card>
        <CardContent>
          <TransMdTypo>
            legal^start
          </TransMdTypo>
          {[...Array(6).keys()].map(index => {
            return <React.Fragment>
              <TransMdTypo variant={"h2"}>
                {`legal^title${index + 1}`}
              </TransMdTypo>
              <TransMdTypo>
                {`legal^content${index + 1}`}
              </TransMdTypo>
            </React.Fragment>
          })
          }
        </CardContent>
      </Card>
    </ResponsiveContainer>
  </Layout>
}


export const LegalPage: Page = {Page: Legal, getUrl: () => "/term-of-use/"}

import {Snackbar} from "@material-ui/core"
import {Alert} from "@material-ui/lab"
import React, {SyntheticEvent} from "react"
import {connect, ConnectedProps} from "react-redux"
import {RootState} from "../../store/store"
import {Message, messageStop} from "./notificationSlice"
import {ThunkDispatch} from "redux-thunk"
import {AnyAction} from "redux"
import styled from "@emotion/styled"


const AlertStyled = styled(Alert)(({theme}) => ({
  backgroundColor: theme.palette.background.highlight,
  color: theme.palette.text.primary
}))
export const NotificationSnackbar = (props: Props) => {

  const handleClose = (event: SyntheticEvent) => {
    props.close(props.message!)
  }

  if (!props.message) return null

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        open={props.message != null}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <AlertStyled onClose={handleClose} severity={props.message?.type}>
          {props.message?.message}
        </AlertStyled>
      </Snackbar>
    </div>
  )
}

const mapState = (state: RootState) => {
  return {message: state.pushMessage.message}
}

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AnyAction>) => {
  return {
    close: (message: Message) => dispatch(messageStop(message))
  }
}

const connector = connect(mapState, mapDispatchToProps)
type Props = ConnectedProps<typeof connector>;
export const MessageSnackbarConnected = connector(NotificationSnackbar)

import * as React from "react"
import {Link} from "../components/i18n/I18nLink"
import {Page} from "../components/PrivateRoutePage"
import {useTranslation} from "gatsby-plugin-react-i18next"
import {Breadcrumbs, BreadcrumbSeparator} from "../components/Breadcrumb"

export const useBreadcrumb = (page: Page) => {
  const breadCrumbsPages = []
  let currentPage: Page | undefined = page

  while (currentPage && currentPage.breadCrumb) {
    breadCrumbsPages.push(currentPage.breadCrumb)

    currentPage = currentPage.parentPage ? currentPage.parentPage() : undefined
  }
  return <Breadcrumbs key={`${Math.random()}`}>
    {breadCrumbsPages.reverse().map((breadCrumb, index) => {
      return <React.Fragment key={`${Math.random()}-${index}`}> {index > 0 && <BreadcrumbSeparator/>} <Link
          css={(theme) => ({color: index < breadCrumbsPages.length - 1 ? theme.palette.text.primary : theme.palette.text.secondary})}
          to={breadCrumb.link}>{breadCrumb.name}</Link></React.Fragment>
      }
    )}
  </Breadcrumbs>

}

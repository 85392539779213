import styled from "@emotion/styled"
import {FormControl, Grid, InputLabel, MenuItem, Paper, Select} from "@material-ui/core"
import React, {useState} from "react"
import {Layout} from "../../components/Layout"
import {ResponsiveContainer, ResponsivePaddedGridItem} from "../../components/Shared"
import {TutorialCard} from "./components/TutorialCard"
import {TutorialModal} from "./components/TutorialModal"
import {TransMdTypo} from "../../components/i18n/TransMdTypo"
import {useTranslation} from "gatsby-plugin-react-i18next"
import {TutorialsYaml} from "../../../graphql-types"
import {Page} from "../../components/PrivateRoutePage"

const TutoFilterBox = styled(Paper)(({theme}) => ({
  padding: theme.spacings.md
}))


export const Tutorials = (props: { pageContext: { allTutorials: {[key:string]: TutorialsYaml[]} } }) => {

  const {t, i18n} = useTranslation()
  const {allTutorials} = props.pageContext
  const thisLangTutorials = allTutorials[`${i18n.language}`]
  const [currentTuto, setCurrentTuto] = useState<TutorialsYaml | null>(null)
  const [category, setCategory] = useState("All")

  const categories = ["All"].concat(
    thisLangTutorials.map(tuto => tuto.category!)
      .filter((v, i, a) => a.indexOf(v) === i)
  )

  const openTutoModal = (tuto: TutorialsYaml) => {
    setCurrentTuto(tuto)
  }

  const closeTutoModal = () => {
    setCurrentTuto(null)
  }

  return (
    <Layout title={t("tutorials^Recalbox Tutorials")}>
      {currentTuto &&
      <TutorialModal open={true} tuto={currentTuto} handleClose={closeTutoModal}/>
      }
      <ResponsiveContainer>
        <TransMdTypo variant="h5">
          tutorials^Featured
        </TransMdTypo>
        <Grid container>
          {thisLangTutorials.filter(tuto => tuto.featured).slice(0,3).map((tuto, i) => {
            return (
              <ResponsivePaddedGridItem>
                <TutorialCard key={`featured-${tuto.title}`} tuto={tuto} onClick={() => openTutoModal(tuto)}/>
              </ResponsivePaddedGridItem>
            )
          })}
        </Grid>

        <TransMdTypo variant="h5">
          tutorials^All
        </TransMdTypo>
        <TutoFilterBox>
          <FormControl>
            <InputLabel id="category">{t("tutorials^Category")}</InputLabel>
            <Select
              labelId="category"
              id="category"
              value={category}
              onChange={(event) => setCategory(event.target.value as string)}
            >
              {categories.map((category, i) => {
                return <MenuItem value={category}>{category === "All" ? t("tutorials^All") : category}</MenuItem>
              })}
            </Select>
          </FormControl>
        </TutoFilterBox>
        <Grid container>
          {thisLangTutorials
            .filter((tuto) => category === "All" || tuto.category === category)
            .map((tuto) => {
              return <ResponsivePaddedGridItem>
                <TutorialCard key={`all-${tuto.title}`} tuto={tuto} onClick={() => openTutoModal(tuto)}/>
              </ResponsivePaddedGridItem>
            })}
        </Grid>

      </ResponsiveContainer>
    </Layout>
  )
}

export const TutorialsPage: Page = {Page: Tutorials, getUrl: () => "/tutorials"}
export default TutorialsPage.Page

import {Paper, StepLabel} from "@material-ui/core"
import * as React from "react"
import {UnstyledLink} from "../../../components/i18n/I18nLink"
import {Diy1Discover} from "../Diy1Discover"
import {Diy2Experience} from "../Diy2Experience"
import {useTranslation} from "gatsby-plugin-react-i18next"
import {ResponsiveStep, ResponsiveStepper} from "../../../components/ResponsiveStep"


export const DiySteps = (props: { active: number, linkToHardware?: string }) => {

  const {t} = useTranslation()
  return (
    <Paper>
      <ResponsiveStepper activeStep={props.active}>
        <ResponsiveStep key="0" className={props.active === 0 ? "active" : ""}>
          <StepLabel><UnstyledLink to={Diy1Discover.getUrl()}>{t("diy^Discover")}</UnstyledLink></StepLabel>
        </ResponsiveStep>
        <ResponsiveStep key="1" className={props.active === 1 ? "active" : ""}>
          <StepLabel><UnstyledLink to={Diy2Experience.getUrl()}>{t("diy^Experience")}</UnstyledLink></StepLabel>
        </ResponsiveStep>
        <ResponsiveStep key="2" className={props.active === 2 ? "active" : ""}>
          <StepLabel>
            {props.linkToHardware ?
              <UnstyledLink to={props.linkToHardware}>{t("diy^Hardware")}</UnstyledLink>
              :
              <span>{t("diy^Hardware")}</span>
            }
          </StepLabel>
        </ResponsiveStep>
        <ResponsiveStep key="3" className={props.active === 3 ? "active" : ""}>
          <StepLabel>{t("diy^Installation")}</StepLabel>
        </ResponsiveStep>
        {/*        <ResponsiveStep key="4">
          <StepLabel>Jouer</StepLabel>
        </ResponsiveStep>*/}
      </ResponsiveStepper>
    </Paper>
  )
}

import {BiReset} from "@react-icons/all-files/bi/BiReset"
import {Button, Fab} from "@material-ui/core"
import React, {useState} from "react"
import {Dialog} from "../../../../components/Dialog"


export const ResetDraftButton = (props: { handleReset: () => void }) => {
  const [dialog, setDialog] = useState(false)
  return (
    <React.Fragment>
      <Dialog display={dialog}
              handleClose={() => {
                setDialog(false)
              }}
              title={"Reset draft to original version ?"}
              text={"The draft will be reseted to the current version of the post (published on the blog). Do you want to continue ?"}
              actions={[
                {
                  button: <Button color={"secondary"} variant={"outlined"}>Oh, no</Button>, callback: () => {
                  }
                },
                {button: <Button>Yes, reset</Button>, callback: props.handleReset},
              ]
              }/>
      <Fab color={"primary"} onClick={() => setDialog(true)} size={"small"}>
        <BiReset/>
      </Fab>
    </React.Fragment>
  )
}

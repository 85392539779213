import CookieConsent from "react-cookie-consent"
import * as React from "react"
import {useTheme} from "@emotion/react"
import {Link, useTranslation} from "gatsby-plugin-react-i18next"
import {LegalPage} from "../features/legal/LegalPage"
import {Button, Grid, Typography} from "@material-ui/core";
import {useLocation} from "@reach/router";


export const RGPDManager = () => {
  const theme = useTheme()
  const {t} = useTranslation()
  const location = useLocation()

  return <CookieConsent
    enableDeclineButton
    location="bottom"
    buttonText={t("legal^Accept")}
    declineButtonText={t("legal^Decline")}
    cookieName="recalboxCookieConsent"
    style={{background: theme.palette.background.highlight, zIndex: 9999, color: "black"}}
    ButtonComponent={Button}
    buttonStyle={{
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.main,
      borderRadius: theme.radius.medium
    }}
    declineButtonStyle={{
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.radius.medium
    }}
    expires={365}
    onDecline={() => {
      if(window) {
        window.location.replace("https://www.google.com")
      }
    }
    }
  >
    <Grid container alignItems={"center"} spacing={1}>
      <Grid item>
        <Typography>{t("legal^This website uses cookies to enhance the user experience.")}</Typography>
      </Grid>
      <Grid item>
        <Button component={Link} to={LegalPage.getUrl()} variant={"outlined"}>
          {t("legal^More info")}
        </Button>
      </Grid>
    </Grid>
  </CookieConsent>
}

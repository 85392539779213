import React from "react"
import CircularProgress, {CircularProgressProps} from "@material-ui/core/CircularProgress"
import {Avatar, Badge} from "@material-ui/core"
import {createStyles, withStyles} from "@material-ui/styles"
import styled from "@emotion/styled"
import {Theme} from "@emotion/react"

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      height: "10%",
      width: "10%",
      minWidth: "8px",
      minHeight: "8px",
      borderRadius: "100%",
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "$ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: "\"\""
      }
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0
      }
    }
  })
)(Badge)

type OnlineProps = { children: JSX.Element, online?: boolean; }
const Online = (props: OnlineProps): JSX.Element => {
  if (props.online) {
    return (
      <StyledBadge
        overlap="circle"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        variant="dot"
      >
        {props.children}
      </StyledBadge>
    )
  } else return props.children
}

type SizeProps = { size: number }
const StyledAvatar = styled(Avatar)((props: SizeProps) => ({
  width: `${props.size}px`, height: `${props.size}px`,
  transition: "all 1s ease",
}))

type Props = { online?: boolean, avatarUrl: string, size: number };

const AvatarBox = styled.div({
  top: "0px",
  left: "0px",
  bottom: "0px",
  right: "0px",
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
})

const WholeBox = styled.div((props: { theme?: Theme, size: number, bgcolor: "paper" | "default" }) => ({
  position: "relative",
  display: "inline-flex",
  backgroundColor: props.theme!.palette.background[props.bgcolor],
  padding: `${props.size * 0.08}px`,
  borderRadius: "100%",
  ":hover": {
    "& .MuiAvatar-root": {
      transform: "scale(1.2)",
      zIndex: 9999
    }
  }
}))

export function CircularAvatar(props: CircularProgressProps & Props) {
  return (
    <WholeBox size={props.size} bgcolor={"paper"}>
      <AvatarBox>
        <StyledAvatar alt="User" src={props.avatarUrl} size={props.size}/>
      </AvatarBox>
      <Online online={props.online}>
        <CircularProgress color={"secondary"} variant="determinate" {...props} />
      </Online>
    </WholeBox>
  )
}

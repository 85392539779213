import * as React from "react"
import {PropsWithChildren} from "react"
import {Card, CardContent} from "@material-ui/core"
import {TransMdTypo} from "../../../components/i18n/TransMdTypo"

export const DiyHardwareCard = (props: PropsWithChildren<{ title: string, subtitle: string }>) => {
  return <Card>
    <CardContent>
      <TransMdTypo variant={"h3"} gutterBottom>{props.title}</TransMdTypo>
      <TransMdTypo gutterBottom>{props.subtitle}</TransMdTypo>
      {props.children}
    </CardContent>
  </Card>
}

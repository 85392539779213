import styled from "@emotion/styled"
import {Step, Stepper} from "@material-ui/core"

export const ResponsiveStep = styled(Step)(({theme}) => ({
  [`@media (max-width: ${theme!.breakpoints.values.sm}px)`]: {
    display: "none",
  },
  "&.active": {
    display: "block"
  }
}))
export const ResponsiveStepper = styled(Stepper)(({theme}) => ({
  [`@media (max-width: ${theme!.breakpoints.values.sm}px)`]: {
    "& .MuiStepConnector-root": {
      display: "none"
    }
  }
}))

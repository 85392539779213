import {Button, ButtonProps, Tooltip, withStyles} from "@material-ui/core"
import * as React from "react"
import {Layout} from "../../components/Layout"
import styled from "@emotion/styled"
import {FaFacebook} from "@react-icons/all-files/fa/FaFacebook"
import {FaDownload} from "@react-icons/all-files/fa/FaDownload"
import {Theme} from "@emotion/react"
import {FaShoppingBasket} from "@react-icons/all-files/fa/FaShoppingBasket"
import {FaTwitch} from "@react-icons/all-files/fa/FaTwitch"
import {Es, Fr, It} from "react-flags-select"
import {FaYoutube} from "@react-icons/all-files/fa/FaYoutube"
import recalboxLogo from "../../data/images/recalbox-white.svg"
import {FaFacebookMessenger} from "@react-icons/all-files/fa/FaFacebookMessenger"
import {FaYoutubeSquare} from "@react-icons/all-files/fa/FaYoutubeSquare"
import {FaTwitter} from "@react-icons/all-files/fa/FaTwitter"
import {FaInstagram} from "@react-icons/all-files/fa/FaInstagram"
import {FaLinkedin} from "@react-icons/all-files/fa/FaLinkedin"
import {useTranslation} from "gatsby-plugin-react-i18next"
import {FaDiscord} from "@react-icons/all-files/fa/FaDiscord"
import {FaPatreon} from "@react-icons/all-files/fa/FaPatreon";


export const RecalboxLinks = [
  {
    title: "Patreon",
    link: "https://patreon.com/recalbox",
    icon: <FaPatreon/>,
    tooltip: "Support Recalbox on Patreon!",
    socialnetwork: true,
  },
  {
    title: "Discord",
    link: "https://discord.gg/GQJREVqrU2",
    icon: <FaDiscord/>,
    tooltip: "Chat with the Recalbox Community.",
    socialnetwork: true,
  },
  {
    title: "Forum",
    link: "https://forum.recalbox.com/",
    icon: <FaTwitch/>,
    tooltip: "Recalbox community forum, find help and support.",
    socialnetwork: false,
  }, {
    title: "Facebook World",
    link: "https://www.facebook.com/recalbox",
    icon: <FaFacebook/>,
    tooltip: "World Facebook page of Recalbox",
    socialnetwork: true,
    color: "#3b5998",
    except: "fr"
  }, {
    title: "Facebook FR",
    link: "https://www.facebook.com/recalbox.fr",
    icon: <FaFacebook/>,
    tooltip: "French Facebook page of Recalbox",
    language: <Fr/>,
    socialnetwork: true,
    color: "#3b5998",
    only: "fr"
  },
  {
    title: "Recalbox Shop",
    link: "https://shop.recalbox.com",
    icon: <FaShoppingBasket/>,
    tooltip: "Recalbox Shop, hats and goodies.",
    language: <Fr/>,
    socialnetwork: false,
  },
  {
    title: "kubii.com",
    link: "https://www.kubii.com/221-recalbox",
    icon: <FaShoppingBasket/>,
    tooltip: "Shop by kubii.com, raspberry pi hardware.",
    language: <Fr/>,
    socialnetwork: false,
    only: "fr"
  },
  {
    title: "Shop by kubii.es",
    link: "https://www.kubii.es/221-recalbox",
    icon: <FaShoppingBasket/>,
    tooltip: "Shop by kubii.es, raspberry pi hardware.",
    language: <Es/>,
    socialnetwork: false,
    only: "es"
  },
  {
    title: "Shop by kubii.it",
    link: "https://www.kubii.it/221-recalbox",
    icon: <FaShoppingBasket/>,
    tooltip: "Shop by kubii.it, raspberry pi hardware.",
    language: <It/>,
    socialnetwork: false,
    only: "it"
  }, {
    title: "Video Tutorials",
    link: "https://www.youtube.com/playlist?list=PL2oNQ0AT7fx2ExiSNrfHUzga5GnogI4sh",
    icon: <FaYoutubeSquare/>,
    tooltip: "Youtube Recalbox Tutorials",
    language: <Fr/>,
  }, {
    title: "Twitch",
    link: "https://www.twitch.com/recalbox",
    icon: <FaTwitch/>,
    tooltip: "French Recalbox Twitch, we are live!",
    language: <Fr/>,
    socialnetwork: true,
    color: "#6441a5"
  }, {
    title: "Groupe Facebook Officiel FR",
    link: "https://www.facebook.com/groups/RecalboxOfficiel/",
    icon: <FaFacebookMessenger/>,
    language: <Fr/>,
    tooltip: "Groupe Facebook Recalbox Officiel France",
    color: "#3b5998",
    only: "fr"
  }, {
    title: "Twitter",
    link: "https://twitter.com/recalbox",
    icon: <FaTwitter/>,
    tooltip: "Follow us on Twitter!",
    socialnetwork: true,
    color:"#1DA1F2"
  }, {
    title: "Youtube",
    link: "https://www.youtube.com/c/RecalboxOfficial",
    icon: <FaYoutube/>,
    tooltip: "All our videos on youtube.",
    socialnetwork: true,
    color: "#ff0000"
  }, {
    title: "Instagram",
    link: "https://instagram.com/recalbox",
    icon: <FaInstagram/>,
    tooltip: "#retrogaming",
    socialnetwork: true,
    color:"#C13584"
  }, {
    title: "LinkedIn",
    link: "https://www.linkedin.com/company/recalbox/",
    icon: <FaLinkedin/>,
    tooltip: "#retrogaming",
  },
]
const LinkTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    fontSize: "1em",
    "& svg": {
      verticalAlign: "middle",
    }
  }
}))(Tooltip)

const LinksContainer = styled.div({
  textAlign: "center",
  paddingTop: "3em",
  width: "400px",
  margin: "auto"
})

const LinkButton = React.forwardRef((props: ButtonProps, ref: React.Ref<HTMLButtonElement & HTMLAnchorElement>) => {
  return (
    <Button ref={ref} {...props} variant={"contained"} color={"secondary"}
            css={{color: "white", fontSize: "1.2em", fontWeight: "bold", width: "100%", marginBottom: "1.5em"}}>
      {props.children}
    </Button>
  )
})


const LinksPage = () => {
  const {t,i18n} = useTranslation()
  const langLinks = RecalboxLinks.filter(link => link.except !== i18n.language && (link.only ? link.only === i18n.language : true))
  return (
    <Layout title={"Recalbox Links"}>
      <LinksContainer>
        <img css={{width: "30%", marginBottom:"1.5em"}} src={recalboxLogo}/>
        <React.Fragment>
          {langLinks.map(link => (
            <LinkTooltip aria-label={link.tooltip}
                         title={<React.Fragment>{link.language} {link.tooltip}</React.Fragment>} arrow
                         placement="bottom">
              <LinkButton startIcon={link.icon} href={link.link}>
                {link.title}
              </LinkButton>
            </LinkTooltip>
          ))}
        </React.Fragment>
      </LinksContainer>
    </Layout>
  )
}


export const Links = {Page: LinksPage, getUrl: () => "/links"}

import React from "react"
import {useI18next} from "gatsby-plugin-react-i18next"
import {Helmet} from "react-helmet"
import {useSiteMetadata} from "../hooks/useSiteMetadata"
import cardOpenGraph from "../data/images/opengraph/card-simple.png"

export type I18HelmetProps = { translatedTitle: string, description?: string, imageUrl?: string }
export const I18nHelmet = (props: I18HelmetProps) => {
  const meta = useSiteMetadata()
  const i18n = useI18next()
  const title = props.translatedTitle
  const metaDescription = props.description || i18n.t("site^site_description")
  const image = props.imageUrl ? `${meta!.siteUrl}${props.imageUrl}` : `${meta!.siteUrl}${cardOpenGraph}`
  const languageInUrl = i18n.language === "en" ? "/" : `/${i18n.language}/`
  return (
    <Helmet
      htmlAttributes={{
        lang: i18n.language
      }}
      title={title}
      titleTemplate={"%s | recalbox.com"}
      meta={[
        {
          name: "description",
          content: metaDescription,
        },
        {
          property: "og:title",
          content: title,
        },
        {
          property: "og:description",
          content: metaDescription,
        },
        {
          property: "og:image",
          content: image,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:creator",
          content: "@digitaLumberjack",
        },
        {
          name: "twitter:title",
          content: title,
        },
        {
          name: "twitter:description",
          content: metaDescription,
        },
        {
          property: "twitter:image",
          content: image,
        },
        {
          name: "viewport",
          content: "minimum-scale=1, initial-scale=1, width=device-width",
        },
      ]}
      link={[
        {
          href: "https://fonts.gstatic.com",
          rel: "preconnect"
        },
        {
          href: "https://fonts.googleapis.com/css?family=Rajdhani:400,500,600,700&display=swap",
          rel: "stylesheet"
        },
        {
          href: "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap",
          rel: "stylesheet"
        },
        {
          type: "application/rss+xml",
          href:`${meta!.siteUrl}${languageInUrl}rss.xml`,
          rel: "alternate",
        }
      ]}
    />
  )
}

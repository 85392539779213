import styled from "@emotion/styled"
import {Modal, Paper, Typography} from "@material-ui/core"
import React from "react"
import YouTube from "react-youtube"
import {TutorialChips} from "./TutorialChips"
import {Tutorial} from "../TutorialPage"

const ModalBox = styled(Modal)(({theme}) => ({
  height: "80%",
  width: "80%",
  overflow: "auto",
  video: {
    width: "100%",
    position: "relative",
    maxHeight: "60vh"
  },
  img: {
    width: "100%",
    margin: "0 auto",
  }
}))
const TutoInformations = styled.div(({theme}) => ({
  padding: theme.spacings.md
}))
const TutoYouTube = styled(YouTube)(({theme}) => ({
  width: "100%",
  height: "60vh"
}))
const TutoPaper = styled(Paper)(({theme}) => ({
  paddingBottom: "3rem"
}))


export const TutorialModal = (props: { tuto: Tutorial, open: boolean, handleClose: () => void }) => {
  return <ModalBox
    open={props.open}
    onClose={props.handleClose}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    style={{inset: "10% 0 0 10%"}}
  >
    <TutoPaper>
      <TutoInformations>
        <Typography variant="h5" gutterBottom>
          {props.tuto.title}
        </Typography>
        <Typography paragraph>
          {props.tuto.description}
        </Typography>
        <TutorialChips tuto={props.tuto}/>
      </TutoInformations>
      {props.tuto.video &&
      <video controls>
        <source src={props.tuto.video.publicURL!} type="video/mp4"/>
      </video>
      }
      {props.tuto.image &&
      <img src={props.tuto.image.publicURL!} alt={props.tuto.title!}/>
      }
      {props.tuto.youtube &&
      <TutoYouTube
        videoId={props.tuto!.youtube}
        id={props.tuto.youtube}
      />
      }

    </TutoPaper>
  </ModalBox>
}

import React from "react"
import throttle from "lodash/throttle"
import clsx from "clsx"
import {makeStyles} from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import {Trans} from "gatsby-plugin-react-i18next"
import {Link} from "@material-ui/core"

const useStyles = makeStyles((theme) => ({

  contents: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
  },
  ul: {
    padding: 0,
    margin: 0,
    listStyle: "none",
  },
  item: {
    color: "inherit",
    fontSize: ".8125rem",
    padding: theme.spacing(0.5, 0, 0.5, `${Math.max(0, theme.spacing(1) - 3)}px`),
    borderLeft: "3px solid transparent",
    boxSizing: "border-box",
    "&:hover": {
      borderLeftColor:
        theme.palette.type === "light" ? theme.palette.grey[200] : theme.palette.grey[900],
    },
    "&$active,&:active": {
      borderLeftColor:
        theme.palette.type === "light" ? theme.palette.grey[300] : theme.palette.grey[800],
    },
  },
  secondaryItem: {
    paddingLeft: theme.spacing(2.5),
  },
  active: {},
}))

// TODO: these nodes are mutable sources. Use createMutableSource once it's stable
function getItemsClient(headings: any[]) {
  const itemsWithNode: any[] = []

  headings.forEach((item) => {
    itemsWithNode.push({
      ...item,
      node: document.getElementById(item.hash),
    })

    if (item.children.length > 0) {
      item.children.forEach((subitem: { hash: string; }) => {
        itemsWithNode.push({
          ...subitem,
          node: document.getElementById(subitem.hash),
        })
      })
    }
  })
  return itemsWithNode
}

const noop = () => {
}

function useThrottledOnScroll(callback: (() => void) | null, delay: number | undefined) {
  const throttledCallback: any = React.useMemo(() => (callback ? throttle(callback, delay) : noop), [
    callback,
    delay,
  ])

  React.useEffect(() => {
    if (throttledCallback === noop) {
      return undefined
    }

    window.addEventListener("scroll", throttledCallback)
    return () => {
      window.removeEventListener("scroll", throttledCallback)
      throttledCallback.cancel()
    }
  }, [throttledCallback])
}

export const AppTableOfContents = (props: { items: any; }) => {
  const {items} = props
  const classes = useStyles()

  const itemsWithNodeRef: any = React.useRef([])
  React.useEffect(() => {
    itemsWithNodeRef.current = getItemsClient(items)
  }, [items])

  const [activeState, setActiveState] = React.useState(null)
  const clickedRef = React.useRef(false)
  const unsetClickedRef: any = React.useRef(null)
  const findActiveIndex = React.useCallback(() => {
    // Don't set the active index based on scroll if a link was just clicked
    if (clickedRef.current) {
      return
    }

    let active
    for (let i = itemsWithNodeRef.current.length - 1; i >= 0; i -= 1) {
      // No hash if we're near the top of the page
      if (document.documentElement.scrollTop < 200) {
        active = {hash: null}
        break
      }

      const item = itemsWithNodeRef.current[i]

      if (process.env.NODE_ENV !== "production") {
        if (!item.node) {
          console.error(`Missing node on the item ${JSON.stringify(item, null, 2)}`)
        }
      }

      if (
        item.node &&
        item.node.offsetTop <
        document.documentElement.scrollTop + document.documentElement.clientHeight / 8
      ) {
        active = item
        break
      }
    }

    if (active && activeState !== active.hash) {
      setActiveState(active.hash)
    }
  }, [activeState])

  // Corresponds to 10 frames at 60 Hz
  useThrottledOnScroll(items.length > 0 ? findActiveIndex : null, 166)

  const handleClick = (hash: React.SetStateAction<null>) => (event: { defaultPrevented: any; button: number; metaKey: any; ctrlKey: any; altKey: any; shiftKey: any; }) => {
    // Ignore click for new tab/new window behavior
    if (
      event.defaultPrevented ||
      event.button !== 0 || // ignore everything but left-click
      event.metaKey ||
      event.ctrlKey ||
      event.altKey ||
      event.shiftKey
    ) {
      return
    }

    // Used to disable findActiveIndex if the page scrolls due to a click
    clickedRef.current = true
    unsetClickedRef.current = setTimeout(() => {
      clickedRef.current = false
    }, 1000)

    if (activeState !== hash) {
      setActiveState(hash)
    }
  }

  React.useEffect(
    () => () => {
      clearTimeout(unsetClickedRef.current)
    },
    [],
  )

  const itemLink = (item: { hash: React.SetStateAction<null>; text: any; }, secondary?: boolean) => (
    <Link
      display="block"
      color={activeState === item.hash ? "textPrimary" : "textSecondary"}
      href={`#${item.hash}`}
      underline="none"
      onClick={handleClick(item.hash)}
      className={clsx(
        classes.item,
        {[classes.secondaryItem]: secondary},
        activeState === item.hash ? classes.active : undefined,
      )}
    >
      <span dangerouslySetInnerHTML={{__html: item.text}}/>
    </Link>
  )

  return (
    <nav aria-label={"pageTOC"}>
      {items.length > 0 ? (
        <React.Fragment>
          <Typography gutterBottom className={classes.contents}>
            <Trans>Table of content</Trans>
          </Typography>
          <Typography component="ul" className={classes.ul}>
            {items.map((item: any) => (
              <li key={item.text}>
                {itemLink(item)}
                {item.children.length > 0 ? (
                  <ul className={classes.ul}>
                    {item.children.map((subitem: any) => (
                      <li key={subitem.text}>{itemLink(subitem, true)}</li>
                    ))}
                  </ul>
                ) : null}
              </li>
            ))}
          </Typography>
        </React.Fragment>
      ) : null}
    </nav>
  )
}

import {GrowGrid, NewExternalLink, SectionTitle} from "../../../components/Shared"
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid
} from "@material-ui/core"
import {GatsbyImage} from "gatsby-plugin-image"
import {useTranslation} from "gatsby-plugin-react-i18next"
import {DownloadBoardsYaml, RecalboxChannelsYaml} from "../../../../graphql-types"
import React, {useState} from "react"
import {EmotionJSX} from "@emotion/react/types/jsx-namespace"
import {MdTypo, TransMdTypo} from "../../../components/i18n/TransMdTypo"

const getBadges = (board: DownloadBoardsYaml) => {
  const chips: EmotionJSX.Element[] = []
  if (board.legacy) {
    chips.push(<Chip css={{marginRight: "0.5em"}} label={"Legacy"}
                     size={"small"} color={"default"}/>)
  }
  const allTag = board.tags?.map(tag => <Chip css={{marginRight: "0.5em"}} label={tag}
                                              size={"small"} color={"primary"}/>)
  if (allTag !== undefined) {
    chips.push(...allTag)
  }
  return chips
}

const ReleaseNotesPopup = (props: { open: boolean, releaseNotes: string, handleClose: () => void }) => {
  const {t} = useTranslation()
  return <Dialog
    open={props.open}
    onClose={props.handleClose}
    maxWidth={"lg"}
  >
    <DialogTitle id="alert-dialog-title">
      {t("patreon^Release Notes")}
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        <MdTypo>
          {props.releaseNotes}
        </MdTypo>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.handleClose} autoFocus>
        {t("Ok")}
      </Button>
    </DialogActions>
  </Dialog>
}
export const AllImagesComponent = (props: { channel: RecalboxChannelsYaml, boards: DownloadBoardsYaml[], ignoreBoardVersion?: boolean, showReleaseNote?: boolean, extraMessage?:React.ReactNode }) => {
  const {t} = useTranslation()
  const {channel, boards, ignoreBoardVersion, showReleaseNote, extraMessage} = props
  const [showReleaseNotes, setShowReleaseNotes] = useState(false)
  const openReleaseNotes = () => setShowReleaseNotes(true)
  const closeReleaseNotes = () => setShowReleaseNotes(false)
  return <React.Fragment>
    <SectionTitle>
      {t("download^All images for Recalbox", {version: channel.version})}
    </SectionTitle>
    <div>
      {extraMessage}
    </div>
    {showReleaseNote && channel.releasenotes &&
    <React.Fragment>
      <ReleaseNotesPopup releaseNotes={channel.releasenotes} open={showReleaseNotes} handleClose={closeReleaseNotes}/>
      <Button css={{marginTop: "1em"}} variant={"outlined"} onClick={openReleaseNotes}>Release Notes</Button>
    </React.Fragment>
    }
    {boards.map(board => {
      const imageLink = `https://${channel.domain}.recalbox.com/latest/download/${board.imagedirectory ?? board.imagename}/recalbox-${board.imagename}.img.xz`
      const imageSha1 = `${imageLink}.sha1`
      return <Card css={{marginTop: "1em"}}>
        <CardContent>

          <Grid container spacing={1} alignItems={"center"}>
            <Grid item>
              <Avatar css={{height: "100%", width: "initial"}}>
                <GatsbyImage alt={board.name!} image={board.picture!.childImageSharp!.gatsbyImageData}/>
              </Avatar>
            </Grid>
            <GrowGrid item>
              <NewExternalLink
                href={imageLink}>{`Recalbox ${ignoreBoardVersion ? channel.version : board.recalboxVersion ?? channel.version} - ${board.name!}`}</NewExternalLink>
              <div>{getBadges(board)}</div>
            </GrowGrid>
            <Grid item>
              <Grid container spacing={1} wrap={"nowrap"}>
                <Grid item>
                  <Button variant={"outlined"} color={"secondary"} href={imageLink}>{t("download^Download")}</Button>
                </Grid>
                <Grid item>
                  <Button variant={"outlined"} href={imageSha1}>(.sha1)</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

    })}
  </React.Fragment>
}

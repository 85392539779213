import styled from "@emotion/styled"
import {Card} from "@material-ui/core"

export const FocusableCard = styled(Card)(({theme}) => ({
  transition: "transform ease 0.5s",
  border: "3px solid #00000000",
  boxSizing: "inherit",
  ":focus,:focus-within,:hover": {
    transform: "scale(1.1)",
    border: `3px solid ${theme.palette.secondary.main}`
  }
}))
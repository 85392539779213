import {Fr, Us} from "react-flags-select"
import React from "react"

export const flags = {
  "fr": <Fr/>,
  "en": <Us/>
}

export const getFlag = (language: string) => {
  return flags[language as keyof typeof flags]
}

import {Button, Fab} from "@material-ui/core"
import React, {useState} from "react"
import {Dialog} from "../../../../components/Dialog"
import {IoCloudUploadOutline} from "@react-icons/all-files/io5/IoCloudUploadOutline"


export const PublishDraftButton = (props: { handlePromote: () => void }) => {
  const [dialog, setDialog] = useState(false)
  return (
    <React.Fragment>
      <Dialog display={dialog}
              handleClose={() => {
                setDialog(false)
              }}
              title={"Publish the post"}
              text={"The draft will be published on the blog. Do you want to continue ?"}
              actions={[
                {
                  button: <Button color={"secondary"} variant={"outlined"}>Nope</Button>, callback: () => {
                  }
                },
                {button: <Button>Yes, publish</Button>, callback: props.handlePromote},
              ]
              }/>
      <Fab title={"Publish post"} color={"primary"} onClick={() => setDialog(true)} size={"small"}>
        <IoCloudUploadOutline/>
      </Fab>
    </React.Fragment>
  )
}

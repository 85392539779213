import {Grid} from "@material-ui/core"
import {Link, useTranslation} from "gatsby-plugin-react-i18next"
import * as React from "react"
import {Layout} from "../../components/Layout"
import {DiySteps} from "./components/DiySteps"
import {CTAButton, PaddedGrid, PreTitle, Title} from "./components/Shared"

import {Page} from "../../components/PrivateRoutePage"
import {TransMdTypo} from "../../components/i18n/TransMdTypo"
import {ResponsiveContainer} from "../../components/Shared"
import {CgArrowRight} from "@react-icons/all-files/cg/CgArrowRight"
import {Diy4Install} from "./Diy4Installation"
import {DiyHardwareCard} from "./components/DiyHardwareCard"
import {StaticImage} from "gatsby-plugin-image"


const Diy3PCPage = () => {
  const {t} = useTranslation()
  return (

    <Layout title={t("diy^DIY Step 3 PC")}>
      <DiySteps active={2}/>
      <ResponsiveContainer>
        <PaddedGrid container spacing={3}>
          <Grid item xs={12} md={9} lg={9}>
            <PreTitle>
              {t("diy^Use the power of your PC to run Recalbox!")}
            </PreTitle>
            <Title>{t("diy^Step 3 : The Hardware")}</Title>
          </Grid>
          <Grid xs={12} item>
            <DiyHardwareCard title={"diy^You just need a PC!"}
                             subtitle={"diy^Recalbox on 64bits"}>
              <Grid container>
                <Grid item xs={8}>
                  <TransMdTypo children={"diy^Minimal Requirement"}/>
                  <TransMdTypo>
                    diy^See PC on the wiki
                  </TransMdTypo>
                </Grid>
                <Grid xs={4} item>
                  <StaticImage src={"../../data/images/diy/x86_64.png"} alt={"PC"}/>
                </Grid>
              </Grid>
            </DiyHardwareCard>
          </Grid>
        </PaddedGrid>
        <PaddedGrid container>
          <CTAButton css={{marginTop: "1em"}} endIcon={<CgArrowRight/>} component={Link}
                     to={Diy4Install.getUrl(Diy3PC.getUrl())}
                     variant="outlined"
                     color="secondary">
            {t("diy^Step 4: Installation")}
          </CTAButton>
        </PaddedGrid>
      </ResponsiveContainer>
    </Layout>

  )
}

export const Diy3PC: Page = {Page: Diy3PCPage, getUrl: () => "/diy/3-pc"}

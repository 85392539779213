import * as React from "react"
import {Features} from "../features/home/Features"
import {HowToBegin} from "../features/home/HowToBegin"
import {Jumbo} from "../features/home/Jumbo"
import {Layout} from "../components/Layout"

const Home = () => {
  return (
    <Layout title={"Recalbox Home"}>
      <Jumbo video/>
      <Features/>
      <HowToBegin/>
    </Layout>
  )
}

const getUrl = () => "/"

export const HomePage = {Page: Home, getUrl, breadCrumb: {name: "Home", link: getUrl()}}

export default HomePage.Page

import {graphql} from "gatsby"
import {BlogPostWithGatsby} from "../../api/ParseObjects"
import {BlogPost} from "../../../graphql-types"

export const query = graphql`
	fragment BlogPostsFragment on BlogPost {
    i18nId
    state
    title
    content
    language
    objectId
    tags
    original
    publishDate {
      iso
    }
    localJumbo {
			childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
        original {
          src
        }
      }
      
    }
    localImages {
      name
      publicURL
    }
    wroteBy {
      username
      twitter
      avatar {
        url
      }
      background {
        url
      }
    }
	}
`

export const toBlogPost = (post: BlogPost): BlogPostWithGatsby => {
  const publishDate = new Date(post.publishDate?.iso ? Date.parse(post.publishDate.iso) : 0)
  let content = post.content!
  for (const image of post.localImages!) {
    const re = new RegExp(`!\\[(.*?)\\]\\((.*?${image!.name}.*?)\\)`, "g")
    content = content.replace(re, `![$1](${image!.publicURL})`)
  }
  return {...post, content, publishDate} as BlogPostWithGatsby
}

import {Card, CardContent, CardMedia, Grid, Typography} from "@material-ui/core"
import {Link, useTranslation} from "gatsby-plugin-react-i18next"
import * as React from "react"
import {Layout} from "../../components/Layout"
import {CgArrowRight} from "@react-icons/all-files/cg/CgArrowRight"
import {DiySteps} from "./components/DiySteps"
import {CTAButton, PaddedGrid, PreTitle, Title} from "./components/Shared"
import {TransMdTypo} from "../../components/i18n/TransMdTypo"
import {ContainImage, FullHeightCard, ResponsiveContainer} from "../../components/Shared"
import {Page} from "../../components/PrivateRoutePage"
import {Diy2Experience} from "./Diy2Experience"
import diy from "../../data/images/diy/diy.svg"
import coin from "../../data/images/diy/coin.gif"
import systems from "../../data/images/diy/systems.png"
import nextGen from "../../data/images/diy/next-gen.svg"
import recalboxWhiteLogoSvg from "../../data/images/recalbox-white-original.svg"

const Diy1DiscoverPage = () => {
  const {t} = useTranslation()
  return (
    <Layout title={t("diy^DIY Step 1")}>
      <DiySteps active={0}/>
      <ResponsiveContainer>
        <Grid container>
          <Grid item xs={12} md={9} lg={9}>
            <PreTitle>
              {t("diy^We are going to help you throught 4 steps")}
            </PreTitle>
            <Title>{t("diy^Step 1: What is Recalbox?")}</Title>
            {/* <Title2><GiGameConsole/> C'est LA console retro next gen !</Title2>
            <TransMdTypo>faq^Qu’est-ce que Recalbox ?</TransMdTypo>*/}
            <br/>
          </Grid>
          {/*<SelfCenterGrid xs={12} md={3} lg={3}>
            <TextCenter>
              <DiyImage src={recalboxImage}/>
            </TextCenter>
          </SelfCenterGrid>*/}
        </Grid>
        <Card>
          <CardContent>
            <Grid container spacing={3} alignItems={"center"} alignContent={"center"}>
              <Grid item xs={12} sm={2}>
                <ContainImage src={recalboxWhiteLogoSvg} alt={"Recalbox logo"} css={{maxHeight: "6em"}}/>
                {/*<StaticImage src={"../../data/images/diy/neobg.png"} alt={"Neo retro image"} css={{width: "100%"}}/>*/}
              </Grid>
              <Grid item xs={12} sm={10}>
                <Typography variant={"h2"} css={{
                  marginTop: "1em",
                  marginBottom: "0.7em"
                }}>{t("diy^It's the next gen console of retrogaming!")}</Typography>
                <TransMdTypo>diy^What is Recalbox?</TransMdTypo>
              </Grid>
            </Grid>
            <Grid container spacing={5} css={{marginTop: "2em"}}>
              {[{
                title: t("diy^NEXT GEN"),
                image: nextGen,
                text: t("diy^Play online")
              },
                {
                  title: t("diy^+100 systems"),
                  image: systems,
                  text: t("diy^Play again to 100")
                },
                {
                  title: t("diy^DIY or Ready To Play"),
                  image: diy,
                  text: t("diy^Assemble your recalbox or get a ready to play kit")
                },
                {
                  title: t("diy^Free"),
                  image: coin,
                  text: t("diy^Recalbox is free")
                }].map(feature => {
                return <Grid item xs={12} sm={6} md={3}>
                  <FullHeightCard css={(theme) => ({backgroundColor: theme.palette.background.surface1})}>
                    <CardMedia component={"img"} src={feature.image} css={{
                      margin: "auto",
                      paddingTop: "0.7em",
                      height: "100px",
                      width: "70%",
                      objectFit: "contain"
                    }}/>
                    <CardContent>
                      <Typography variant={"h6"} align={"center"} gutterBottom>{feature.title}</Typography>
                      <Typography variant={"body2"}>{feature.text}</Typography>
                    </CardContent>
                  </FullHeightCard>
                </Grid>
              })}
            </Grid>
          </CardContent>
        </Card>
        <PaddedGrid container>
          <CTAButton endIcon={<CgArrowRight/>} component={Link} to={Diy2Experience.getUrl()} variant="outlined"
                     color="secondary">
            {t("diy^Step 2: Select your experience")}
          </CTAButton>
        </PaddedGrid>
      </ResponsiveContainer>
    </Layout>
  )
}

export const Diy1Discover: Page = {Page: Diy1DiscoverPage, getUrl: () => "/diy/1-discover"}

import styled from "@emotion/styled"
import {Theme, Typography, TypographyProps} from "@material-ui/core"
import React from "react"
import systemsConsoles from "../../data/images/index/banniere-consoles.png"

export const IndexSectionTitle = (props: TypographyProps) => {
  return <Typography css={(theme) => ({marginBottom: theme.spacings.xl})} variant={"h2"}>{props.children}</Typography>
}
export const ElementTitle = styled(Typography)(({theme}) => ({
  fontSize: "1.2rem",
}))
export const PaddedContainer = styled.div((props) => ({
  padding: props.theme!.spacing(3),
  paddingTop: props.theme!.spacing(12),
  paddingBottom: props.theme!.spacing(12),
  textAlign: "center",
}))
export const ImageContainer = styled(PaddedContainer)((props) => ({
  background: `url(${systemsConsoles})`,
  backgroundSize: "contain",
  backgroundRepeat: "repeat",
  padding: props.theme!.spacing(3),
  paddingTop: props.theme!.spacing(12),
  paddingBottom: props.theme!.spacing(12),
}))
export const ColorContainer = styled.div((props: { theme?: Theme, color?: string }) => ({
  backgroundColor: props.color ?? props.theme!.palette.background.default,
  color: props.theme!.palette.getContrastText(props.color ?? props.theme!.palette.background.default),
}))

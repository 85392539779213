import {useTranslation} from "gatsby-plugin-react-i18next"
import {DownloadStepper, Steps} from "./components/DownloadStepper"
import {MdTypo, TransMdTypo} from "../../components/i18n/TransMdTypo"
import {Box, Button, Card, CardContent, Grid, Typography} from "@material-ui/core"
import {Layout} from "../../components/Layout"
import {GrowGrid, ResponsiveContainer} from "../../components/Shared"
import {DownloadBoardsYaml, DownloadCategoriesYaml, RecalboxChannelsYaml} from "../../../graphql-types"

import rpiImagerCustomMp4 from "../../data/videos/download/newrpiimager-image.mp4-processed.mp4"
import rpiImagerCustomWebm from "../../data/videos/download/newrpiimager-image.mp4.webm"
import rpiImagerMp4 from "../../data/videos/download/newrpiimager.mp4-processed.mp4"
import rpiImagerWebm from "../../data/videos/download/newrpiimager.mp4.webm"
import * as React from "react"
import styled from "@emotion/styled"
import {Link} from "../../components/i18n/I18nLink"
import {PlayPage} from "./PlayPage"
import downloadOGImage from "../../data/images/opengraph/card-download.png"
import eventBG from "../../data/images/index/event-bg.png"
import {StaticImage} from "gatsby-plugin-image"
import {PatreonPage} from "../patreon/PatreonPage"
import {AnnouncementButton, AnnouncementCard} from "../home/Jumbo"

const DownloadButton = styled(Button)({
  marginRight: "0.5em",
  marginBottom: "0.5em"
})
export const Install = (props: { pageContext: { alternative: boolean, board: DownloadBoardsYaml, channel: RecalboxChannelsYaml, category: DownloadCategoriesYaml } }) => {
  const {alternative, board, category} = props.pageContext
  const channel = props.pageContext.channel
  const {t} = useTranslation()
  const video = alternative ?
    {
      webm: rpiImagerCustomWebm,
      mp4: rpiImagerCustomMp4
    }
    :
    {
      webm: rpiImagerWebm,
      mp4: rpiImagerMp4
    }
  const domain = channel.domain
  return (
    <Layout title={`Recalbox Download - ${t("download^Installation", {board: board.name})}`}
            description={t("download^Download and install Recalbox for your", {category: board.name})}
            imageUrl={downloadOGImage}>
      <DownloadStepper step={Steps.Install} board={board}
                       channel={channel}
                       category={category}/>
      <ResponsiveContainer>

        {process.env.GATSBY_PATREON_ENABLED === "true" &&
          <AnnouncementCard>
            <div css={{backgroundImage: `url(${eventBG})`}}>
              <CardContent css={{position: "relative"}}>

                <Grid container>
                  <GrowGrid item>
                    <Grid container>
                      <Grid item md={5}>
                        <StaticImage src={"../../data/images/index/recalboxxpatreon.png"} alt={"Recalbox X Patreon"}/>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <TransMdTypo>patreon^Recalbox needs you! Support the project on Patreon and enjoy premium features!</TransMdTypo>
                    </Grid>
                  </GrowGrid>

                  <Grid item>
                    <AnnouncementButton variant={"outlined"} component={Link}
                                        to={PatreonPage.getUrl()}>
                      {t("home^Read more...")}
                    </AnnouncementButton>
                  </Grid>
                </Grid>

              </CardContent>
            </div>
          </AnnouncementCard>
        }
        <Card css={{marginTop:"1em"}}>
          <CardContent>
            <Typography variant={"h3"} gutterBottom>
              {t("download^Let's download", {board: board.name})}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TransMdTypo>
                  download^Rasberry Pi Imager Description
                </TransMdTypo>
              </Grid>
              <Grid item sm={12} md={6}>
                <video autoPlay muted loop css={{width: "100%", borderRadius: "1em"}}>
                  <source src={video.mp4} type="video/mp4"/>
                  <source src={video.webm} type="video/webm"/>
                </video>
              </Grid>
              <Grid item sm={12} md={6}>
                <MdTypo gutterBottom>
                  {`1. ${t("download^Step1")}`}
                </MdTypo>
                <Box marginLeft={"2em"}>
                  <DownloadButton variant={"contained"} color={"primary"}
                                  href={"https://downloads.raspberrypi.org/imager/imager_latest.exe"}>Windows</DownloadButton>
                  <DownloadButton variant={"contained"} color={"primary"}
                                  href={"https://downloads.raspberrypi.org/imager/imager_latest_amd64.deb"}>Linux</DownloadButton>
                  <DownloadButton variant={"contained"} color={"primary"}
                                  href={"https://downloads.raspberrypi.org/imager/imager_latest.dmg"}>MacOs</DownloadButton>
                </Box>
                <MdTypo gutterBottom>
                  {`2. ${alternative ? t("download^Step2Alternative", {board: board.name}) : t("download^Step2")}`}
                </MdTypo>
                {alternative &&
                <Box marginLeft={"2em"}>
                  <DownloadButton variant={"contained"} color={"secondary"}
                                  href={`https://${domain}.recalbox.com/latest/download-wizard/${board.imagedirectory ?? board.imagename}/recalbox-${board.imagename}.img.xz`}>
                    {t("download^Download Recalbox for", {board: board.name})} ({board.recalboxVersion ?? props.pageContext.channel.version})
                  </DownloadButton>
                </Box>
                }
                <MdTypo gutterBottom>
                  {`3. ${alternative ? t("download^Step3Alternative") : t("download^Step3", {board: board.rpiImagerImageName ?? board.name})}`}
                </MdTypo>
                <MdTypo gutterBottom>
                  {`4. ${t("download^Step4")}`}
                </MdTypo>
                <TransMdTypo gutterBottom>
                  {`5. ${t("download^Step5")}`}
                </TransMdTypo>
              </Grid>
              {!alternative &&
              <Grid item xs={12}>
                <Link
                  to={AlternativeInstallPage.getUrl(channel.name!, category.id, board.id)}>{t("download^Alternative image")}</Link>
              </Grid>
              }
              <Grid item xs={12} css={{textAlign: "center"}}>
                <Button component={Link} to={PlayPage.getUrl(channel.name, category.id, board.id)} variant={"outlined"}>
                  {t("download^Next: play")}</Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </ResponsiveContainer>
    </Layout>)
}


export const InstallPage = {
  Page: Install,
  getUrl: (channel: string, category: string, board: string) => `/download/${channel}/${category}/${board}/`
}
export const AlternativeInstallPage = {
  Page: Install,
  getUrl: (channel: string, category: string, board: string) => `/download/${channel}/${category}/${board}/alternative/`
}

export default InstallPage.Page

import styled from "@emotion/styled"
import {Fab, Grid} from "@material-ui/core"
import {RiImageAddLine} from "@react-icons/all-files/ri/RiImageAddLine"
import React, {ChangeEvent} from "react"


const UploadedImage = styled.img({
  flex: 1,
  flexBasis: "20%",
  width: "20%",
  flexGrow: 0,
  flexShrink: 1,
  height: "100%",
  objectFit: "cover"
})

export const ImageUploader = (props: {
  id: string, multiple: boolean,
  handleImageClick: (imageUrl: string, ev: React.MouseEvent<HTMLDivElement>) => void,
  handleImageDrag: (imageUrl: string, ev: React.DragEvent<HTMLDivElement>) => void,
  handleImageAdd: (event: ChangeEvent<HTMLInputElement>) => void, images: string[]
}) => {
  return (
    <Grid container spacing={4}>
      <Grid item>
        <input
          accept="image/*"
          id={props.id}
          multiple={props.multiple}
          type="file"
          onChange={props.handleImageAdd}
          css={{display: "none"}}
        />
        <label htmlFor={props.id}>
          <Fab component="span" color="primary" aria-label="add">
            <RiImageAddLine size={30}/>
          </Fab>
        </label>
      </Grid>
      <Grid item xs={10}>
        {props.images.map((image) => {
          return <UploadedImage src={image} draggable
                                onClick={(e) => props.handleImageClick(image, e)}
                                onDragStart={(ev) => props.handleImageDrag(image, ev)}/>
        })}
      </Grid>
    </Grid>
  )
}

import {Link, useTranslation} from "gatsby-plugin-react-i18next"
import {DownloadStepper, Steps} from "./components/DownloadStepper"
import {TransMdTypo} from "../../components/i18n/TransMdTypo"
import {Card, CardActionArea, CardContent, Grid, Typography} from "@material-ui/core"
import {Layout} from "../../components/Layout"
import {ResponsiveContainer} from "../../components/Shared"
import {ElementCard} from "./components/ElementCard"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import {DownloadBoardsYaml, DownloadCategoriesYaml, RecalboxChannelsYaml} from "../../../graphql-types"
import {FileNode} from "gatsby-plugin-image/dist/src/components/hooks"
import {AlternativeInstallPage, InstallPage} from "./InstallPage"
import downloadOGImage from "../../data/images/opengraph/card-download.png"

export const Board = (props: { pageContext: { boards: DownloadBoardsYaml[], channel: RecalboxChannelsYaml, category: DownloadCategoriesYaml } }) => {
  const boards = props.pageContext.boards.map(node => ({
    gatsbyImage: getImage((node.picture as any) as FileNode),
    ...node
  }))
  const {category} = props.pageContext
  const channel = props.pageContext.channel
  const {t} = useTranslation()
  return (
    <Layout title={`Recalbox Download - ${category.name} ${t("download^Board Selection")}`}
            description={t("download^Download and install Recalbox for your", {category: category.name})}
            imageUrl={downloadOGImage}>
      <DownloadStepper step={Steps.Board} channel={channel} category={category}/>
      <ResponsiveContainer>
        <Card>
          <CardContent>
            <TransMdTypo variant={"h3"}>
              {t("download^What model is your", {category: category.name ? t(`download^${category.name}`) : category.name})}
            </TransMdTypo>

            <Grid container spacing={2}>
              {boards.map(board => {
                const link = board.defaultInstall === "rpiimager" && channel.name === "stable" ? InstallPage.getUrl(channel.name, category.id, board.id) : AlternativeInstallPage.getUrl(channel.name!, category.id, board.id)
                return <Grid item xs={6} md={3}>
                  <ElementCard>
                    <CardActionArea component={Link} to={link}>
                      <GatsbyImage image={board.gatsbyImage!} alt={board.name!} objectFit={"contain"}/>
                      <Typography>
                        {board.name}
                      </Typography>
                      <Typography>
                        ({board.recalboxVersion ?? channel.version})
                      </Typography>
                    </CardActionArea>
                  </ElementCard>
                </Grid>
              })
              }
            </Grid>
          </CardContent>
        </Card>
      </ResponsiveContainer>
    </Layout>)
}


export const BoardPage = {
  Page: Board,
  getUrl: (channel: string, category: string) => `/download/${channel}/${category}/`
}

export default BoardPage.Page

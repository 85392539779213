import {Box} from "@material-ui/core"
import * as React from "react"
import {RecalboxSystemsSection} from "./RecalboxSystemsSection"
import {SimplicitySection} from "./SimplicitySection"
import {FeatureSection} from "./FeatureSection"

export const Features = (props: { className?: string }) => {
  return (
    <Box className={props.className}>
      <RecalboxSystemsSection/>
      <SimplicitySection/>
      <FeatureSection/>
    </Box>
  )
}

import {Layout} from "../../components/Layout"
import {Link, useTranslation} from "gatsby-plugin-react-i18next"
import {Button, Card, CardActionArea, CardContent, Grid, GridSize, Typography} from "@material-ui/core"
import {GrowGrid, ResponsiveContainer} from "../../components/Shared"
import {TransMdTypo} from "../../components/i18n/TransMdTypo"
import {DownloadStepper, Steps} from "./components/DownloadStepper"
import {ElementCard} from "./components/ElementCard"
import {CategoryPage} from "./CategoryPage"
import downloadOGImage from "../../data/images/opengraph/card-download.png"

import {ReleaseNotesPage} from "./ReleaseNotesPage"
import {TextCenter} from "../diy/components/Shared"
import {RecalboxChannelsYaml} from "../../../graphql-types"
import {Redirect} from "@reach/router"
import {useI18nLink} from "../../components/i18n/I18nLink"
import {navigate} from "gatsby"


export const Channel = (props: { pageContext: {channels: RecalboxChannelsYaml[]}}) => {
  const {t} = useTranslation()
  const {channels} = props.pageContext
  const {getLink} = useI18nLink()
  if (typeof window === "undefined") {
    return <Redirect to={getLink(CategoryPage.getUrl("stable"))}/>
  }else {
    navigate(getLink(CategoryPage.getUrl("stable")))
  }
  return (
    <Layout title={`Recalbox Download - ${t("download^Channel Selection")}`}
            description={t("download^Download and install Recalbox for your Rasbperry Pi, PC, Handheld and Odroid!")}
            imageUrl={downloadOGImage}>
      <DownloadStepper step={Steps.Category}/>
      <ResponsiveContainer>
      </ResponsiveContainer>
    </Layout>
  )
}

export const ChannelPage = {Page: Channel, getUrl: () => "/download/"}

export default ChannelPage.Page

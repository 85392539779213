import {useCookies} from "react-cookie"
import {PatronInfo} from "../../../../../patreon-api/src/patron/Patron";
import {Badge} from "../../../../../patreon-api/src/badges/Types";

const clientId = process.env.GATSBY_PATREON_CLIENT_ID || "9Kzd164FnzMWF32SmULG0Z71LHFDPD4xWroa-c-xtl7xagF7Ca51rQYoeD38cOV2"
const redirectURL = process.env.GATSBY_PATREON_REDIRECT_URL || "http://localhost:8000/patreon/patron/logged"
const recalboxApiUrl = process.env.GATSBY_PATREON_API_URL || "https://api-patreon.recalbox.com"
const debug = process.env.GATSBY_DEBUG
const stateCookieName = "recalbox-patreon-state"
const tokenCookieName = "recalbox-patreon-token"


export const usePatreon = () => {
  const [cookies, setCookies, deleteCookie] = useCookies([stateCookieName, tokenCookieName])

  const getUserInfo = (): Promise<PatronInfo> => {
    if (false) {
      return Promise.resolve({
        full_name: "DEBUG",
        email: "DEBUG",
        patron_status: "active_patron",
        tier_status: 3,
        patron_since: "2022-05-20T09:00:15.231+00:00",
        tiers: [{tier_title: "DEBUG TIER", since: new Date().toISOString()}]
      })
    }
    if(!cookies[tokenCookieName]){
      return Promise.reject("Not connected")
    }
    return fetch(`${recalboxApiUrl}/userinfo`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${cookies[tokenCookieName]?.access_token}`
      }
    }).then(response => {
      try {
        if (response.status === 200)
          return response.json() as Promise<PatronInfo>
      } catch (e) {
      }
      return Promise.reject()
    }).catch(error => Promise.reject(error))
  }

  const setToken = (token: any) => {
    deleteCookie(tokenCookieName, {path: "/", domain: debug ? "localhost" : "recalbox.com"})
    setCookies(tokenCookieName, {...token, rbversion: 1}, {
      path: "/",
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      domain: debug ? "localhost" : "recalbox.com"
    })
  }
  return {
    loginUrl: (state: String) => `https://www.patreon.com/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectURL}&state=${state}&scope=identity%20identity%5Bemail%5D%20campaigns%20campaigns.members`,
    currentToken: () => {
      return cookies[tokenCookieName]?.access_token
    },
    clearAll: () => {
      deleteCookie(stateCookieName, {path: "/"})
      deleteCookie(tokenCookieName, {path: "/", domain: debug ? "localhost" : "recalbox.com"})
    },
    checkState: (state: string) => {
      return state === cookies[stateCookieName]
    },
    setState: (state: string) => {
      deleteCookie(stateCookieName, {path: "/"})
      setCookies(stateCookieName, state, {path: "/"})
    },
    setToken,
    migrateToken: () => {
      if (cookies[tokenCookieName] && !cookies[tokenCookieName].rbversion) {
        setToken({...cookies[tokenCookieName]})
      }
    },
    getUserInfo,
    getBadges: () => {
      return fetch(`${recalboxApiUrl}/badges`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${cookies[tokenCookieName]?.access_token}`
        }
      }).then(response => {
        try {
          if (response.status === 200)
            return response.json() as Promise<Badge[]>
        } catch (e) {
        }
        return Promise.reject()
      }).catch(error => Promise.reject(error))
    },
    isPatron: (): Promise<boolean> => {
      if (false)
        return Promise.resolve(true)
      return getUserInfo().then(info => info.patron_status === "active_patron").catch(e => false)
    },
    createToken: (code: string,) => {
      return fetch(`${recalboxApiUrl}/getToken?code=${code}&redirect_uri=${redirectURL}`, {
        method: "POST",
      })
    }
  }
}


import styled from "@emotion/styled"
import {FocusableCard} from "../../myrecalbox/components/FocusableCard"
import {Card} from "@material-ui/core";

export const ElementCard = styled(Card)(({theme}) => ({
  backgroundColor: theme.palette.background.surface1,
  textAlign: "center",
  marginTop: "2em",
  padding: "0.5em",
  img: {
    padding: "1em",
    maxHeight: "280px",
  }
}))

import {TextCenter} from "../diy/components/Shared"
import {Grid} from "@material-ui/core";
import patreonLogo from "../../data/images/patreon/recalboxxpatreon-v2.svg"

export const PatreonTitleGrid = () => {
  return <Grid item xs={12}>
    <TextCenter css={{paddingTop: "1em", paddingBottom: "2em"}}>
      <img css={{width:"80%", margin:"auto"}} src={patreonLogo}
                   alt={"recalbox + patreon"}/>
    </TextCenter>
  </Grid>
}

import {Card, Grid, Tab} from "@material-ui/core";
import {TabContext, TabList} from "@material-ui/lab";
import React from "react";
import {ProfilePage} from "./ProfilePage";
import {useNavigate} from "@reach/router";
import {useI18nLink} from "../../../components/i18n/I18nLink";
import {GoodiesPage} from "./GoodiesPage";
import {BadgesPage} from "./BadgesPage";
import {DownloadPatronVersionPage} from "./DownloadPatronVersionPage";
import {RecalboxRGBDualOrderPage} from "../../rgbdual/order/RecalboxRGBDualOrder";

const tabs = {
  "profile": {name: "Profile", link: () => ProfilePage.getUrl()},
  "rrgbd": {name: "Order RGB Dual", link: () => RecalboxRGBDualOrderPage.getUrl()},
  "badges": {name: "Badges", link: () => BadgesPage.getUrl()},
  "goodies": {name: "Goodies", link: () => GoodiesPage.getUrl()},
  /*"roadmap": {name: "Roadmap", link: () => RoadmapPage.getUrl()},*/
  "download": {name: "Download Patron Edition", link: () => DownloadPatronVersionPage.getUrl()},
}

type TabType = keyof typeof tabs

export const PatronNav = (props: { tab: TabType }) => {
  const nav = useNavigate()
  const i18nLink = useI18nLink()
  const handleClick = (tab: TabType) => {
    nav(i18nLink.getLink(tabs[tab].link()))
  }
  return <TabContext value={props.tab}>
    <Grid container spacing={2} css={{marginBottom: "2em"}}>
      <Grid item xs={12}>
        <Card>
          <TabList scrollButtons={"auto"} variant={"scrollable"}>
            {Object.entries(tabs).map(tab => (
              <Tab value={tab[0]} label={tab[1].name} onClick={() => handleClick(tab[0] as TabType)}/>
            ))}
          </TabList>
        </Card>
      </Grid>
    </Grid>
  </TabContext>
}

import {Typography, TypographyProps} from "@material-ui/core"
import {useTranslation} from "gatsby-plugin-react-i18next"
import * as React from "react"
import ReactMarkdown from "react-markdown"
import {Highlight} from "../Shared"


const reactMarkdownRenderers = {
  emphasisHighlight: (data: any) => {
    const text = data.node.children[0]?.value ?? data.node.children[0].children[0].value
    return <Highlight>{text}</Highlight>
  },
  image: (data: any) => {
    return null
  }
}
export const TransMdTypo = (props: TypographyProps & React.PropsWithChildren<any>) => {
  const {t} = useTranslation()
  return (
    <Typography {...props}>
      <TransMd>
        {t(props.children)}
      </TransMd>
    </Typography>
  )
}

export const MdTypo = (props: TypographyProps & React.PropsWithChildren<any>) => {
  const {t} = useTranslation()
  return (
    <Typography {...props}>
      <ReactMarkdown>
        {props.children}
      </ReactMarkdown>
    </Typography>
  )
}
export const TransMd = (props: { children: string }) => {
  const {t} = useTranslation()
  return (
    <ReactMarkdown renderers={{}} children={t(props.children)!}/>
  )
}

const MdRaw = (props: { children: string, className?: string }) => {
  return <ReactMarkdown allowDangerousHtml={true} className={props.className} renderers={{}} children={props.children}/>
}


export const InlineMdRaw = (props: { children: string, className?: string }) => {
  return <MdRaw css={{display: "inline", p: {display: "inline"}}} children={props.children}/>
}

export const MdWithParagraphs = (props: { children: string }) => {
  return <MdRaw css={(theme) => ({
    p: {
      marginBottom: theme.spacing(2)
    },
    img: {
      maxWidth: "100%"
    }
  })} children={props.children}/>
}

export const Md = (props: { children: string }) => {
  return <MdRaw css={(theme) => ({
    img: {
      maxWidth: "100%"
    }
  })} children={props.children}/>
}

export const MdLowerThird = (props: { children: string, fontSize?: string, fontWeight?: number }) => {
  return <ReactMarkdown css={{
    whiteSpace: "nowrap",
    fontSize: `${props.fontSize ?? "1em"}`,
    fontWeight: props.fontWeight ?? 400,
    img: {
      maxWidth: "100%",
    }
  }} renderers={{emphasis: reactMarkdownRenderers.emphasisHighlight}} children={props.children}/>

}

export const MdNoImages = (props: { children: string, className?: string }) => {
  return <ReactMarkdown allowDangerousHtml={true} className={props.className}
                        renderers={{image: reactMarkdownRenderers.image}}
                        children={props.children}/>
}

import styled from "@emotion/styled"
import {Theme} from "@material-ui/core"
import CssBaseline from "@material-ui/core/CssBaseline"
import * as React from "react"
import {ReactNode} from "react"
import {connect, ConnectedProps} from "react-redux"
import {MessageSnackbarConnected} from "../features/notification/NotificationSnackbar"
import {getTheme} from "../theme"
import {Nav} from "../features/navigation/Navigation"
import {ThemeSwitch} from "./ThemeSwitch"
import {RootState} from "../store/store"
import {switchElement} from "../features/profile/profileSlice"
import {ThunkDispatch} from "redux-thunk"
import {AnyAction} from "redux"
import {CSSTransition} from "react-transition-group"
import {ThemeProvider} from "./ThemeProvider"
import {I18HelmetProps, I18nHelmet} from "./I18nHelmet"
import { useTranslation} from "gatsby-plugin-react-i18next"
import {RGPDManager} from "./RGPDManager"
import {Page} from "./PrivateRoutePage"
import {Link} from "./i18n/I18nLink"

const Container = styled.div`
  ${(props: { hideAppBar?: boolean, theme?: Theme, noMargin?: boolean }) => `
    @media (min-width: ${props.theme!.breakpoints.values.xs}px) {
        margin-left: 0px;
    }
    @media (min-width: ${props.theme!.breakpoints.values.sm}px) {
        margin-left: ${props.noMargin ? 0 : props.theme!.nav.width.xs}px;
    }
    @media (min-width: ${props.theme!.breakpoints.values.md}px) {
        margin-left: ${props.noMargin ? 0 : props.theme!.nav.width.sm}px;
    }
    margin-top: ${props.hideAppBar ? "0" : props.theme!.toolbar.height}px;
    height: calc(100vh - ${props.hideAppBar ? "0" : props.theme!.toolbar.height}px);
`};
`


export const LayoutT = (props: LayoutProps) => {
  const recalboxMode = props.recalboxMode || false
  const {t} = useTranslation()
  const switches = (
    <React.Fragment>
      <ThemeSwitch lightMode={props.lightMode} switch={() => props.switchElement("lightMode")}/>
    </React.Fragment>
  )
  const theme = props.withTheme ?? getTheme(recalboxMode, props.lightMode)

  return (
    <div>
      <I18nHelmet translatedTitle={props.title} imageUrl={props.imageUrl} description={props.description}/>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        {!props.hideCookieConsent &&
        <RGPDManager></RGPDManager>
        }
        {props.noNav !== true &&
        <Nav title={props.title} hideAppBar={props.hideAppBar}></Nav>
        }
        <CSSTransition appear in={!recalboxMode} timeout={1000} classNames="opacity">
          <Container hideAppBar={props.noNav || props.hideAppBar}
                     noMargin={props.noNav || recalboxMode}>
            {props.children}</Container>
        </CSSTransition>
        <MessageSnackbarConnected/>
      </ThemeProvider>
    </div>
  )
}

const mapState = (state: RootState) => {
  return {
    isLogged: state.profile.isLogged,
    lightMode: state.profile.lightMode,
  }
}

const mapDispatch = (dispatch: ThunkDispatch<RootState, any, AnyAction>) => {
  return {
    switchElement: (name: "lightMode") => dispatch(switchElement(name))
  }
}

export type BreadCrumbElement = {name:string, link:string}


const connector = connect(mapState, mapDispatch)
type LayoutProps =
  ConnectedProps<typeof connector> & Partial<I18HelmetProps>
  & { hideCookieConsent?: boolean, recalboxMode?: boolean, children?: ReactNode,
  title: string, hideAppBar?: boolean, withTheme?: Theme, noNav?: boolean};
export const Layout = connector(LayoutT)

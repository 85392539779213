import {blue} from "@material-ui/core/colors"
import {Button, Card, CardContent, CardMedia, Grid, InputAdornment, TextField, Typography} from "@material-ui/core"
import * as React from "react"
import {ChangeEvent, useEffect, useState} from "react"
import {graphql, useStaticQuery} from "gatsby"
import {Query, RecalboxSystem} from "../../../graphql-types"
import {GatsbyImage} from "gatsby-plugin-image"
import Carousel, {autoplayPlugin, slidesToShowPlugin} from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"
import {useTheme} from "@emotion/react"
import {Link} from "../../components/i18n/I18nLink"
import {DatabaseSystems} from "../database/SystemsPage"
import {ColorContainer, ImageContainer, IndexSectionTitle} from "./IndexShared"
import {FaSearch} from "@react-icons/all-files/fa/FaSearch"
import {useTranslation} from "gatsby-plugin-react-i18next"
import {TransMdTypo} from "../../components/i18n/TransMdTypo"


const SystemCard = (props: { system: RecalboxSystem }) => {
  const {system} = props
  return <Card css={{backgroundColor: "transparent", boxShadow: "none"}}>
    <CardMedia>
      <GatsbyImage image={system.illustrationLocal?.childImageSharp?.gatsbyImageData}
                   alt={system.noms?.nom_eu ?? "???"}/>
    </CardMedia>
    <CardContent>
      <Typography variant={"h5"}>{system.noms?.nom_eu}</Typography>
      {/*<Typography variant={"h5"}>Runs on:</Typography>
                    <Typography>Raspberry PI 4</Typography>
                    <Typography>Odroid Go Advance</Typography>
                    <Typography>PC 64 Bits</Typography>*/}
    </CardContent>
  </Card>
}

export const RecalboxSystemsSection = () => {

  const [search, setSearch] = useState("")
  const [systems, setSystems] = useState<RecalboxSystem[]>([])
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | undefined>(undefined)
  const theme = useTheme()
  const {t} = useTranslation()
  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const lastSearch = ev.currentTarget.value
    setSearch(lastSearch)
    typingTimeout && clearTimeout(typingTimeout)
    setTypingTimeout(setTimeout(() => {
      filterSystems(lastSearch)
    }, 500))
  }

  const recalboxSystems = useStaticQuery<Query>(graphql`
  query RecalboxSystem {
    allRecalboxSystem {
      edges {
        node {
          systemId
          noms {
            nom_eu
            noms_commun
            nom_jp
          }
          illustrationLocal {
            childImageSharp {
              gatsbyImageData(
               height: 120,
               placeholder: BLURRED
             )
            }
          }
        }
      }
    }
  }
  `).allRecalboxSystem.edges.map(_ => _.node)

  const filterSystems = (search: string) => {
    const systemsFound = recalboxSystems.filter(_ => _.noms?.noms_commun?.toLowerCase().includes(search.toLowerCase()))
    setSystems(systemsFound.length > 0 ? systemsFound : recalboxSystems)
  }

  useEffect(() => {
    filterSystems("")
  }, [])

  const plugins = [
    "infinite",
    "clickToChange",
    "centered",
    {
      resolve: autoplayPlugin,
      options: {
        interval: 2000,
      }
    },
    {
      resolve: slidesToShowPlugin,
      options: {
        numberOfSlides: 3
      }
    },
  ]

  const breakpoints = {
    [`${theme.breakpoints.values.sm}`]: {
      plugins: [
        ...plugins,
        {
          resolve: slidesToShowPlugin,
          options: {
            numberOfSlides: 1
          }
        },
      ]
    }, [`${theme.breakpoints.values.md}`]: {
      plugins: [
        ...plugins,
        {
          resolve: slidesToShowPlugin,
          options: {
            numberOfSlides: 2
          }
        },
      ]
    }
  }

  return (
    <ColorContainer color={blue["900"]}>
      <ImageContainer>
        <IndexSectionTitle>{t("home^More than 100 compatible systems")}</IndexSectionTitle>
        <TransMdTypo>home^More than 100 compatible systems details</TransMdTypo>

        <TransMdTypo gutterBottom>home^What do you want to play?</TransMdTypo>
        <TextField css={{margin: "20px", marginBottom: "30px"}} variant={"standard"}
                   label={t("home^I want to play to...")}
                   color={"secondary"} value={search} onChange={handleChange}
                   InputProps={{
                     startAdornment: (
                       <InputAdornment position="start">
                         <FaSearch/>
                       </InputAdornment>
                     ),
                   }}/>
        <div>
          {systems.length > 1 ?
            <Carousel
              plugins={plugins}
              breakpoints={breakpoints}
              animationSpeed={1000}>
              {systems.map((system) => <SystemCard key={system.systemId} system={system}/>)}
            </Carousel>
            :
            <Grid container spacing={2} justify={"center"}>
              {systems.map((system) => {
                return (
                  <Grid item xs={12} sm={4}>
                    <SystemCard key={system.systemId} system={system}/>
                  </Grid>
                )
              })}
            </Grid>
          }
        </div>
        <Button component={Link} css={{marginTop: "1em"}} to={DatabaseSystems.getUrl()}
                variant={"outlined"}>{t("home^All the systems")}</Button>
      </ImageContainer>

    </ColorContainer>
  )
}

export const overrides = (linkColor: string) => ({
  MuiButton: {
    containedSecondary: {
      color: "white"
    },
    containedPrimary: {
      color: "white"
    },
  },
  MuiCssBaseline: {
    "@global": {
      a: {
        fontWeight: "700",
        textDecoration: "none",
        color: linkColor,
      },

      "*:focus": {
        outline: "none"
      },
      p: {
        margin: 0
      },
      "*": {
        "scrollbar-width": "thin",
      },
      "*::-webkit-scrollbar": {
        width: "4px",
        height: "4px",
      },
      ".opacity-enter": {
        opacity: 0,
      },
      ".opacity-appear": {
        opacity: 0,
      },
      ".opacity-exit": {
        opacity: 1,
      },
      ".opacity-enter-active": {
        opacity: 1,
        transition: "opacity 1s",
      },
      ".opacity-appear-active": {
        opacity: 1,
        transition: "opacity 1s",
      },
      ".opacity-exit-active": {
        opacity: 0,
        transition: "opacity 1s"
      },
      // opacity 200
      ".opacity200-enter": {
        opacity: 0,
      },
      ".opacity200-appear": {
        opacity: 0,
      },
      ".opacity200-exit": {
        opacity: 1,
      },
      ".opacity200-enter-active": {
        opacity: 1,
        transition: "opacity 200ms",
      },
      ".opacity200-appear-active": {
        opacity: 1,
        transition: "opacity 200ms",
      },
      ".opacity200-exit-active": {
        opacity: 0,
        transition: "opacity 200ms"
      },
      // Translate Right 200
      ...translate("X", "right", 200, 200),
      // Translate Top 200
      ...translate("Y", "top", 200, -200),
      ...translate("Y", "bottom", 200, 200),
      ...spin(),
      ...loading(),
      ".tytop": {
        transform: "translateY(-200%)",
        transition: "all 1s"
      }
    }
  }
})

const spin = () => {
  return {
    "@keyframes spin": {
      "0%": {transform: "scaleX(1)"},
      "50%": {transform: "scaleX(0)"},
      "100%": {transform: "scaleX(1)"}
    }
  }
}

const loading = () => {
  return {
    ".loading":
      {
        animationName: "loading",
        animationDuration: "1s",
        animationIterationCount: "infinite",
        animationTimingFunction: "linear",
      },
    "@keyframes loading": {
      from: {
        transform: "rotate(0deg)"
      },
      to: {
        transform: "rotate(360deg)"
      }
    }
  }
}

const translate = (direction: "X" | "Y", from: string, timeout: number, percent: number) => {
  return {
    [`.translate${direction}-${from}-enter`]: {
      transform: `translate${direction}(${percent}%)`,
    },
    [`.translate${direction}-${from}-appear`]: {
      transform: `translate${direction}(${percent}%)`,
    },
    [`.translate${direction}-${from}-exit`]: {
      transform: `translate${direction}(0)`,
    },
    [`.translate${direction}-${from}-enter-active`]: {
      transform: `translate${direction}(0)`,
      transition: `transform ${timeout}ms`,
    },
    [`.translate${direction}-${from}-appear-active`]: {
      transform: `translate${direction}(0)`,
      transition: `transform ${timeout}ms`,
    },
    [`.translate${direction}-${from}-exit-active`]: {
      transform: `translate${direction}(${percent}%)`,
      transition: `transform ${timeout}ms`,
    }
  }
}
